import urls from 'constants/urls';

export const pagesWithCheckoutScaffolding = [
  {
    url: '/ecom/checkout',
  },
  {
    url: '/ecom/resolve-order-payment/',
    title: 'Payment',
  },
];

export const pagesWithMinimalScaffolding = [
  { url: '?changeslot' },
  { url: urls.forgotPassword },
  { url: urls.login },
  { url: urls.signIn },
  { url: urls.registration },
  { url: urls.registrationAccountDetails },
  { url: urls.registrationSuccess },
  { url: urls.resetPassword },
  { url: urls.logout },
];

export const pagesWithNoHeader = [
  { url: urls.registration },
  { url: urls.registrationAccountDetails },
  { url: urls.registrationSuccess },
];

export const pagesWithoutAmendBar = [
  ...pagesWithMinimalScaffolding,
  {
    url: urls.orderConfirmation,
  },
  {
    url: urls.paymentConfirmationPage,
  },
];

export const pagesWithSpinnerOnlyScaffolding = [
  // the following URL is no longer used, but the comment is here for future reference
  // {
  //   url: '/ecom/some/url',
  // },
];
