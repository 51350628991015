import { createSelector, lruMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import { ALL_CATEGORIES_VALUE, CATEGORY_GROUP } from 'constants/facets';
import { sortFacets } from 'utils/facetOrdering';
import { findem } from 'utils/findem';
import { getPathname as getRoutingPathname } from 'redux/modules/routing/selectors';
import { getProducts, getProductById } from 'redux/modules/entities/selectors/products';
import { getSearchCategory } from 'redux/modules/search/selectors';
import { pathJoin } from 'utils/general';
import { categoryNameToUrl } from 'utils/format';
import { getCategoryOffer } from 'redux/modules/taxonomy/selectors';
import urls from 'constants/urls';

const EMPTY_ARRAY = [];

const getSearchAndBrowse = state => state.searchAndBrowse;
const getFacetName = (state, facetName) => facetName;
const getFacetValueId = (state, facetValueId) => facetValueId;
const getProductId = (state, productId) => productId;

const getPathname = (state, props) => props.location.pathname;
const getHideCategoryFilter = (state, props = {}) => !!props.hideCategoryFilter;

const getCriteria = createSelector(
  [getSearchAndBrowse],
  searchAndBrowse => searchAndBrowse?.criteria,
);

export const getFilters = createSelector(
  [getCriteria],
  criteria => criteria?.filters ?? EMPTY_ARRAY,
);

const getCategoryFilters = createSelector(
  [getSearchAndBrowse, getHideCategoryFilter],
  (searchAndBrowse, hideCategoryFilter) => {
    if (hideCategoryFilter) {
      return EMPTY_ARRAY;
    }

    return searchAndBrowse?.categoryLevelFilters ?? EMPTY_ARRAY;
  },
);

export const getGridItems = state => {
  if (state.searchAndBrowse?.products?.length > 0) return state.searchAndBrowse.products;

  if (state.productGrid?.items?.length > 0) return state.productGrid.items;

  return EMPTY_ARRAY;
};

export const getGridItemsCount = state => {
  const uniqueItems = new Set(
    getGridItems(state)
      .filter(i => i.aemComponent === undefined) // remove aemComponent from total numbers
      .map(i => i.searchProduct),
  );

  return uniqueItems.size;
};

// returns number of products in grid with sponsored products set via AEM
// sponsored products set via adTech should not be taken into account
export const getUniqueSponsoredItemsCount = state => {
  const data = getGridItems(state);
  const uniqueSponsoredItems = data.filter(item => {
    if (item.sponsored) {
      if (getProductById(state, item.searchProduct).sponsorshipId) return false;

      return (
        data.filter(item1 => {
          const match = item.searchProduct === item1.searchProduct;
          return match;
        }).length === 1
      );
    }
    return false;
  });

  return uniqueSponsoredItems.length;
};

export const getGridAemItems = createSelector([getGridItems], gridItems =>
  (gridItems || []).filter(item => item.aemComponent).map(item => item.aemComponent),
);

const isSponsoredProductGridItems = createSelector(
  [getGridItems, (_, lineNumber) => lineNumber],
  (products = [], lineNumber) =>
    !!products.find(item => item.searchProduct === lineNumber && item.sponsored === true),
);

const isSponsoredProductFavourites = createSelector(
  [getProductById],
  (product = {}) => !!product.isSponsored,
);

export const isSponsoredProduct = (state, lineNumber) => {
  return getRoutingPathname(state) === urls.favourites
    ? isSponsoredProductFavourites(state, lineNumber)
    : isSponsoredProductGridItems(state, lineNumber);
};

export const getGridProductLineNumbers = createSelector([getGridItems], (gridItems = []) => [
  ...new Set(gridItems.map(item => item.searchProduct).filter(lineNumber => lineNumber)),
]);

export const getProductFacets = createSelector([getFilters], filterGroups =>
  sortFacets(filterGroups),
);

export const getProductFacetValuesByFacetName = createSelector(
  [getProductFacets, getFacetName],
  (facets, facetName) => {
    const facet = facets.find(item => item.group === facetName);
    if (facet && facet.filters) {
      return facet.filters.map(facetValue => facetValue.filterTag);
    }
    return EMPTY_ARRAY;
  },
);

export const getProductFacetValuesByFacetValueId = createSelector(
  [getProductFacets, getFacetValueId],
  (facets, facetValueId) => {
    const facet = facets.find(item =>
      item.filters.some(facetValue => facetValue.filterTag.id === facetValueId),
    );
    if (facet && facet.filters) {
      return facet.filters.map(facetValue => facetValue.filterTag);
    }
    return EMPTY_ARRAY;
  },
);

export const getProductFacetValueById = createSelector(
  [getProductFacetValuesByFacetValueId, getFacetValueId],
  (facet, facetValueId) => facet.find(facetValue => facetValue.id === facetValueId),
);

export const getAppliedProductFacets = createSelector([getProductFacets], facets => {
  let appliedFilters = [];
  facets.forEach(facet => {
    const appliedFacetValues = facet.filters
      .filter(facetValue => facetValue.applied)
      .map(facetValue => facetValue.filterTag);
    appliedFilters = [...appliedFilters, ...appliedFacetValues];
  });
  return appliedFilters;
});

export const getCategoryGroup = createSelector(
  [getCategoryFilters, getSearchCategory],
  (categories, category) => {
    if (!categories.length) return null;

    const deselectOption = {
      deselector: true,
      text: 'All Categories',
      value: ALL_CATEGORIES_VALUE,
    };

    const options = [...categories]
      .sort(({ expectedResults: a }, { expectedResults: b }) => b - a)
      .map(({ expectedResults: count, name: text, categoryId: value }) => ({ count, text, value }));

    return {
      deselectAllOptionsLabel: 'All Categories',
      label: 'Categories',
      name: CATEGORY_GROUP,
      options: [deselectOption, ...options],
      value: category,
    };
  },
);

export const getAppliedFilters = createSelector(
  [getAppliedProductFacets, getSearchCategory, getCategoryFilters, (state, props) => props],
  (appliedFacets, category, categoryFilters, { hideOfferType = false } = {}) => {
    const finalAppliedFacets = hideOfferType
      ? appliedFacets.filter(({ group }) => group !== 'OFFER_TYPE')
      : appliedFacets;

    const selectedCategory =
      category && categoryFilters.find(filter => filter?.categoryId === category);

    return selectedCategory
      ? [
          {
            group: CATEGORY_GROUP,
            text: selectedCategory.name,
            value: category,
            id: category,
          },
          ...finalAppliedFacets,
        ]
      : finalAppliedFacets;
  },
  {
    memoize: lruMemoize,
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);

// Denormalises list of gridItems mainly for use by GTM/Monetate code,
// components using this should be refactored use more appropriate selectors instead
export const getGridItemProducts = createSelector(
  [getGridItems, getProducts],
  (gridItems, productEntities) =>
    gridItems.map(
      gridItem =>
        (gridItem.searchProduct && { searchProduct: productEntities[gridItem.searchProduct] }) ||
        gridItem,
    ),
);

export const getNestedProductPosition = createSelector(
  [getGridItems, getProductId],
  (gridItems, productId) => {
    const hasSearchProductId = item => item.searchProduct === productId;
    const indexOfId = collection => collection.findIndex(hasSearchProductId);
    const collections = findem(gridItems, 'aemComponent.paragraphSystem.childComponents[].items');
    const index = collections.map(indexOfId)[0];
    return index === undefined ? undefined : index + 1;
  },
);

export const getShowPageTitle = createSelector(
  [getSearchAndBrowse],
  (searchAndBrowse = {}) => searchAndBrowse.showPageTitle,
);

export const getPageTitle = createSelector(
  [getSearchAndBrowse],
  (searchAndBrowse = {}) => searchAndBrowse.pageTitle,
);

export const getPageSubTitle = createSelector(
  [getSearchAndBrowse],
  ({ pageSubTitle } = {}) => pageSubTitle,
);

// Naïve calculation of grid position of a product for use by GTM, preserving old behaviour,
// to be replaced soon by a more accurate calculation under another ticket
export const getProductPosition = createSelector(
  [getGridItems, getProductId],
  (gridItems, productId) => {
    const position = gridItems.map(item => item.searchProduct || null).indexOf(productId) + 1;
    return position || undefined;
  },
);

export const getSearchAndBrowseLoading = createSelector(
  [getSearchAndBrowse],
  (searchAndBrowse = {}) => searchAndBrowse.loading,
);

export const getSearchAndBrowseLoaded = createSelector([getCriteria], criteria => !!criteria);

export const isAddAllToBasket = createSelector(
  getSearchAndBrowse,
  ({ addAllToBasket, loading }) => !loading && addAllToBasket,
);

export const getRecommendations = createSelector(
  getSearchAndBrowse,
  ({ recommendations }) => recommendations,
);

export const getSubCategories = createSelector(
  getSearchAndBrowse,
  getPathname,
  ({ subCategories } = {}, pathname) =>
    (subCategories || []).map(subCategory => {
      const urlName = categoryNameToUrl(subCategory.name);
      return {
        id: subCategory.categoryId,
        name: subCategory.name,
        url: pathJoin(pathname, urlName),
        hiddenInNav: subCategory.hiddenInNav,
      };
    }),
);

export const getVisibleSubCategories = createSelector(
  getSubCategories,
  getCategoryOffer,
  getFilters,
  (subCategories, categoryOffer, filters) => {
    const hasOffers = filters?.some(f => f.group === 'OFFER_TYPE');
    if (!subCategories) return null;

    const displayableCategories = subCategories.filter(category => {
      return !category?.hiddenInNav;
    });

    if (!displayableCategories.length) {
      return EMPTY_ARRAY;
    }

    if (!hasOffers) return displayableCategories;

    return categoryOffer ? [categoryOffer].concat(displayableCategories) : displayableCategories;
  },
);

export const getExperiment = createSelector(
  [getSearchAndBrowse],
  searchAndBrowse => searchAndBrowse?.experiment || {},
);
