import type { Fetcher, FetcherArgs } from './fetcher-types';

export const conditional =
  <PathParamKey extends string = string>(
    condition: (args: FetcherArgs<PathParamKey>, getState: () => WtrState) => boolean,
    fetcher: Fetcher<PathParamKey>,
  ): Fetcher<PathParamKey> =>
  (args: FetcherArgs<PathParamKey>) =>
  (dispatch, getState) => {
    if (condition(args, getState)) {
      return dispatch(fetcher(args));
    }

    return Promise.resolve();
  };
