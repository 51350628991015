import { createSelector } from 'reselect';

import { WtrRootState } from 'redux/create-store';

import { OTHERS } from 'constants/categoryIds';

export type Category = {
  id: string;
  categoryIds: string[];
  name: string;
  banner: string;
  hasDescendants: boolean;
  emptyOfProducts: false;
  hiddenInNav: false;
  pageTitle: string;
  categories: {
    [key: string]: Category;
  };
  shortName: string;
  additionalClasses?: string;
  url: string;
};

export type Taxonmy = {
  data: {
    [key: string]: Category;
  };
  depth: number;
  errorResponse: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  footer: any;
  level: string;
  loaded: boolean;
  loading: boolean;
  rootCategories: Category[];
};

type Data = {
  [key: string]: Category;
};

const returnId = (_state: WtrRootState, id: string) => id;

const othersCategory = { id: OTHERS, name: 'Others' };

export const getData = ({ taxonomy }: WtrRootState) => taxonomy?.data as unknown as Data;

export const getCategoryById = createSelector(
  [getData, returnId],
  (data: Data = {}, id: string) => {
    if (OTHERS === id) {
      return othersCategory;
    }
    return {
      ...data[id],
      ...(!!data[id] && { hasDescendants: !!data[id]?.categoryIds?.length }),
    };
  },
);
