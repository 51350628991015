import React from 'react';
import classNames from 'classnames';
import {
  HeartInactive,
  HeartActive,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from 'components/Product/Favourite/Favourite.scss';

type FavouriteProps = {
  hasLabel?: boolean;
  isFavourite?: boolean;
  toggleFavourite?: () => void;
};

const Favourite = ({
  hasLabel = false,
  isFavourite = false,
  toggleFavourite = () => {},
}: FavouriteProps) => (
  <button
    aria-checked={isFavourite}
    aria-label="Favourite"
    className={classNames(styles.button, {
      [styles.noLabel]: !hasLabel,
    })}
    data-testid="favourites-button"
    data-actiontype={isFavourite ? 'remove' : 'insert'}
    data-origincomponent="Favourite"
    data-shortdescription={isFavourite ? 'remove item from favourites' : 'add item to favourites'}
    onClick={toggleFavourite}
    role="checkbox"
    type="button"
  >
    <span className={styles.icon}>
      {isFavourite ? <HeartActive className={styles.active} /> : <HeartInactive />}
    </span>
    {hasLabel && (isFavourite ? 'Remove from favourites' : 'Add to favourites')}
  </button>
);

Favourite.displayName = 'Favourite';

export default Favourite;
