import createReducer from 'redux/createReducer';
import { LOCATION_CHANGE } from 'redux-first-history';
import { deepFreeze } from 'utils/deepFreeze';

import saveLastNonAuthenticationPath from './save-last-non-authentication-path';

import { SAVE_LAST_NON_AUTHENTICATION_PATH } from '../actions/types';

const initialState = deepFreeze({
  lastNonAuthenticationPath: null,
});

const actionTypeReducerMap = new Map([
  [LOCATION_CHANGE, saveLastNonAuthenticationPath],
  [SAVE_LAST_NON_AUTHENTICATION_PATH, saveLastNonAuthenticationPath],
]);

export default createReducer(initialState, actionTypeReducerMap);
