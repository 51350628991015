import { connect } from 'react-redux';

import { currentlyAmendingOrder } from 'redux/modules/amend-order/selectors';
import { isCustomerSlotLoading } from 'redux/modules/customer-slot/selectors';
import { togglePinBar } from 'redux/modules/page/actions';
import amendingOrder from 'redux/modules/trolley/selectors/amending-order';
import getTrolleyLoading from 'redux/modules/trolley/selectors/get-loading';

import SitePinBar from './SitePinBar';

export default connect(
  state => {
    return {
      isOpen: state.page.isPinBarOpen,
      loading: currentlyAmendingOrder(state),
      visible: !isCustomerSlotLoading(state) && !getTrolleyLoading(state) && amendingOrder(state),
    };
  },
  {
    onToggle: togglePinBar,
  },
)(SitePinBar);
