import env from 'env/env';

export const splitPath = (path = '') =>
  path
    .split('/')
    .filter(segment => segment.length > 0 && `/${segment}` !== env.root)
    .map(segment => segment.toLowerCase());

export const BOOK_SLOT = 'bookslot';
export const BROWSE = 'browse';
export const BUNDLES = 'bundles';
export const CHECKOUT = 'checkout';
export const COLLECTION = 'collection';
export const CUSTOM_AISLE = 'customAisle';
export const DELIVERY = 'delivery';
export const ENTERTAINING = 'entertaining';
export const FAVOURITES = 'favourites';
export const FOR_YOU = 'for-you';
export const FEATURED = 'featured';
export const HOME = 'home';
export const INTERSTITIALS = 'interstitials';
export const LISTS = 'lists';
export const MARKETING_PREFERENCES = 'marketingpreferences';
export const MEAL_DEALS = 'mealDeals';
export const MISSED_OFFERS = 'missed-offers';
export const MULTI_SEARCH = 'multi-search';
export const MY_ACCOUNT = 'my-account';
export const MY_ORDERS = 'my-orders';
export const NEW_FEATURES = 'newFeatures';
export const OFFERS = 'offers';
export const ORDER_CONFIRMATION = 'order-confirmation';
export const PAYMENT_CONFIRMATION = 'payment-confirmation';
export const PRODUCTS = 'products';
export const RECIPES = 'recipes';
export const RESOLVE_PAYMENT = 'resolve-payment';
export const SEARCH = 'search';
export const SHOP = 'shop';
export const SHOP_FROM_ORDER = 'shop-from-order';
export const SHOPPING_LISTS = 'shopping-lists';
export const TROLLEY = 'trolley';
export const VIEW_ORDER = 'view-order';
export const UNKNOWN = 'unknown';

export const getPageType = (path?: string | undefined | null) => {
  if (path) {
    const segments = splitPath(path);

    if (segments.length === 0) return HOME;

    if (segments[0] === 'bookslot') {
      if (segments.length === 1) return BOOK_SLOT;

      if ([COLLECTION, DELIVERY].includes(segments[1])) {
        return segments[1];
      }
    }

    if (segments[0] === 'beforeyougo') {
      if (segments[1]?.includes('missed-offers')) return MISSED_OFFERS;
      return INTERSTITIALS;
    }

    if (segments[0] === 'lists') return LISTS;

    if (segments[0] === 'recipes') return RECIPES;

    if (segments[0] === 'favourites') return FAVOURITES;

    if (segments[0] === 'for-you') return FOR_YOU;

    if (segments[0] === 'myaccount') {
      if (segments[1] === 'marketingpreferences') return MARKETING_PREFERENCES;

      if (segments[1] === 'my-orders') {
        if (segments.length === 2) return MY_ORDERS;

        if (segments[2] === 'order') {
          if (segments[3] === 'shop-from-order') {
            return SHOP_FROM_ORDER;
          }

          return VIEW_ORDER;
        }
      }

      return MY_ACCOUNT;
    }

    if (segments[0] === 'order-confirmation') return ORDER_CONFIRMATION;

    if (segments[0] === 'products') return PRODUCTS;

    if (segments[0] === 'shopping-lists') return SHOPPING_LISTS;

    if (segments[0] === 'shop') {
      if (segments.length === 1) return SHOP;

      switch (segments[1]) {
        case 'browse':
          return BROWSE;
        case 'offers':
          return OFFERS;
        case 'search':
          return SEARCH;
        case 'multi-search':
          return MULTI_SEARCH;
        case 'trolley':
          return TROLLEY;
        default:
          break;
      }

      if (segments[1] === 'featured') {
        if (segments.length === 2) return FEATURED;

        switch (segments[2]) {
          case 'entertaining':
            return ENTERTAINING;
          case 'new-features':
            return NEW_FEATURES;
          case 'offers':
            return OFFERS;
          case 'meal-deals':
            return MEAL_DEALS;
          case 'bundles':
            return BUNDLES;
          default:
            return CUSTOM_AISLE;
        }
      }
    }

    if (segments[0] === 'checkout') {
      return CHECKOUT;
    }

    if (segments[0] === 'resolve-order-payment') {
      return RESOLVE_PAYMENT;
    }

    if (segments[0] === 'payment-confirmation') {
      return PAYMENT_CONFIRMATION;
    }
  }

  return UNKNOWN;
};
