/* eslint-disable @typescript-eslint/no-restricted-imports */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  getMetaData,
  getPageTitle,
  getRecipeCardsGrid,
} from 'redux/modules/cms-hybrid-recipes-page/selectors';
import { useSelector } from 'react-redux';
import env from 'env/env';
import { useLocation } from 'react-router-dom';
import Canonical from 'components/Canonical';
import PlannerGrid from './MealPlannerGrid';
import plannerStyles from './index.scss';

const PrebuiltPlanner = () => {
  const pageTitle = useSelector(getPageTitle);
  const grid = useSelector(getRecipeCardsGrid);
  const meta = useSelector(getMetaData);
  const { pathname } = useLocation();

  const metaFields = [{ name: 'robots', content: meta?.turnOffIndexing ? 'noindex' : 'index' }];
  if (meta?.description) {
    metaFields.push({ name: 'description', content: meta.description });
  }

  if (meta?.keywords) {
    metaFields.push({ name: 'keywords', content: meta.keywords });
  }

  const canonicalHref = `https://${env.prodHost}${pathname}`;

  return (
    <>
      <Canonical href={canonicalHref} absolute={false} />
      <Helmet title={pageTitle?.display ? pageTitle?.text : 'Meal plan'} meta={metaFields} />
      <div className={plannerStyles.content}>
        <PlannerGrid title={grid ? grid.gridTitle : ''} subtitle={grid?.gridSubtitle} />
      </div>
    </>
  );
};

export default PrebuiltPlanner;
