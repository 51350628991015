import createReducer from 'redux/createReducer';
import { deepFreeze } from 'utils/deepFreeze';

import { LOCATION_CHANGE } from 'redux-first-history';

import {
  pending,
  fulfilled,
  rejected,
  clearError,
  throttleLogins,
  resetSessionState,
  saveShoppingContext,
} from './sessions';

import setAccessToken from './set-access-token';

import {
  CLEAR_ERROR,
  tokenActionTypes,
  shoppingContextActionTypes,
  SET_ACCESS_TOKEN,
  THROTTLE_LOGINS,
  DISABLE_THROTTLE_LOGINS,
} from '../actions/types';

export const initialState = deepFreeze({
  // deepcode ignore HardcodedNonCryptoSecret - placeholder
  accessToken: 'Bearer unauthenticated',
  loading: false,
  error: null,
  customerId: '-1',
  customerOrderId: '0',
  customerOrderState: null,
  defaultBranchId: '651',
  expiresIn: null,
  permissions: [],
  principalId: '-1',
});

const actionTypeReducerMap = new Map([
  [tokenActionTypes.request, pending],
  [tokenActionTypes.success, fulfilled],
  [tokenActionTypes.failure, rejected],
  [shoppingContextActionTypes.request, pending],
  [shoppingContextActionTypes.success, saveShoppingContext],
  [shoppingContextActionTypes.failure, resetSessionState],
  [SET_ACCESS_TOKEN, setAccessToken],
  [LOCATION_CHANGE, clearError],
  [CLEAR_ERROR, clearError],
  [THROTTLE_LOGINS, throttleLogins],
  [DISABLE_THROTTLE_LOGINS, resetSessionState],
]);

export default createReducer(initialState, actionTypeReducerMap);
