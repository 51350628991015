import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { TypographySecondary } from '@johnlewispartnership/wtr-ingredients/foundations/typography/TypographySecondary';
import Alert from '@johnlewispartnership/wtr-ingredients/ingredients/Alert';
import { Button } from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { useWtrDispatch, useWtrSelector } from 'redux/hooks';
import {
  TrolleyInactive,
  SuccessSolid,
  Warning,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import Spinner from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';
import { dataLayer } from 'analytics/data-layer';
import { IngredientGroups } from 'redux/modules/recipes/index.d';
import { Recipe } from 'api/definitions/recipes/index.d';
import { RecipesRoot } from 'redux/modules/recipes/reducers';
import shoppableModalStyles from '../../Content/Shoppable/ShoppableHeading/index.scss';
import type { RecipeSummaryItem } from '../../../../api/definitions/recipes';
import RecipesAccordion from './RecipesAccordion';
import {
  getNumberOfSelectedShoppableProductsForMultipleRecipes,
  getNumberOfShoppableProductsForMultipleRecipes,
  getRecipesTotalPriceOfSelectedShoppableProducts,
  getNumberOfRecipesWithShoppableProducts,
  getMultipleRecipesShoppableProducts,
  getLineNumbers,
} from '../../../../redux/modules/recipes/selectors';
import { formatAsPounds } from '../../../../utils/currency';
import { getAddAllItemsStatus } from '../../../../redux/modules/add-all-items/selectors';
import locator from '../../../../utils/locator';
import urls from '../../../../constants/urls';
import { getCustomerOrderId, isUserLoggedIn } from '../../../../redux/modules/sessions/selectors';
import addAllItems from '../../../../redux/modules/add-all-items/actions/add-all-items';
import { FULFILLED, PENDING } from '../../../../redux/modules/add-all-items/reducer';
import { getConflictedProducts } from '../../../../redux/modules/trolley-errors/selectors';
import { getProducts } from '../../../../redux/modules/entities/selectors/products';
import clearTrolleyErrors from '../../../../redux/modules/trolley-errors/actions/clear-trolley-errors';
import { LineNumber, ProductDataType } from '../../Content/Shoppable/ProductGrid';
import { ConflictedProduct } from '../../Content/Shoppable/ShoppableHeading';
import { createOrderItems } from '../../Content/Shoppable/ShoppableHeading/utils';
import styles from './index.scss';

interface MealPlannerAddToTrolleyModalProps {
  recipes: (RecipeSummaryItem | null)[];
  isOpen: boolean;
  closeModal: () => void;
}

export type RecipeIdList = (Recipe['id'] | undefined)[];

export type SetSelectedRecipesIds = (
  selectedRecipesIds: RecipeIdList | ((prevSelectedRecipesIds: RecipeIdList) => RecipeIdList),
) => void;

const MealPlannerAddToTrolleyModal = ({
  recipes,
  isOpen,
  closeModal,
}: MealPlannerAddToTrolleyModalProps) => {
  const [selectedRecipesIds, setSelectedRecipesIds] = useState<(string | undefined)[]>(
    recipes.map(recipe => recipe?.id),
  );
  const trolleyStatus = useWtrSelector(getAddAllItemsStatus);
  const [successfulTrolley, setSuccessfulTrolley] = useState(false);
  const isLoggedIn = useWtrSelector(isUserLoggedIn);
  const dispatch = useWtrDispatch();

  const products = useWtrSelector(getProducts);
  const lineNumbers: LineNumber[] = useWtrSelector(getLineNumbers);

  const productData: ProductDataType[] = useMemo(
    () => lineNumbers.map(line => products[line]).filter(Boolean),
    [products, lineNumbers],
  );

  const selectedRecipeIdsMemo = useMemo(() => recipes.map(recipe => recipe?.id), [recipes]);

  useEffect(() => {
    setSelectedRecipesIds(selectedRecipeIdsMemo);
  }, [selectedRecipeIdsMemo]);

  const numberOfSelectedShoppableProducts = useWtrSelector((state: RecipesRoot) =>
    getNumberOfSelectedShoppableProductsForMultipleRecipes(state, selectedRecipesIds),
  );

  const numberOfShoppableProducts = useWtrSelector((state: RecipesRoot) =>
    getNumberOfShoppableProductsForMultipleRecipes(state, selectedRecipesIds),
  );

  const recipesTotalPriceOfSelectedShoppableProducts = useWtrSelector((state: RecipesRoot) =>
    getRecipesTotalPriceOfSelectedShoppableProducts(state, selectedRecipesIds),
  );

  const numberOfRecipesWithShoppableProducts = useWtrSelector((state: RecipesRoot) =>
    getNumberOfRecipesWithShoppableProducts(
      state,
      recipes.map(recipe => recipe?.id),
    ),
  );

  const numberOfSelectedRecipesWithShoppableProducts = useWtrSelector((state: RecipesRoot) =>
    getNumberOfRecipesWithShoppableProducts(state, selectedRecipesIds),
  );

  const getNameByLineNumber = (line: LineNumber) =>
    Object(productData.find(({ lineNumber }) => lineNumber === line)).name;

  const shoppableProducts = useWtrSelector(state =>
    getMultipleRecipesShoppableProducts(state, selectedRecipesIds),
  );

  const conflictedProducts = useWtrSelector(getConflictedProducts);
  const trolleyConflicts: ConflictedProduct[] = conflictedProducts?.map(
    ({ message, products: product }) => ({
      message,
      ingredient: getNameByLineNumber(product?.[0].lineNumber),
    }),
  );

  const addIngredientsToTrolley = () => {
    if (!isLoggedIn) {
      locator.href = `${urls.tokenClient}?redirect=${urls.serviceSelection}`;
      return;
    }

    const orderId = getCustomerOrderId();
    const orderItems = createOrderItems(
      Object.keys(shoppableProducts).flatMap(item => shoppableProducts[item]),
    );

    dispatch(
      addAllItems({
        merge: true,
        orderId,
        orderItems,
      }),
    );
  };

  // Handles trolley success/fail screens
  useEffect(() => {
    if (trolleyStatus === FULFILLED) {
      setSuccessfulTrolley(true);
      setTimeout(() => {
        setSuccessfulTrolley(false);
      }, 1500);
    }
  }, [trolleyConflicts.length, trolleyStatus]);

  const isTrolleyPending = trolleyStatus === PENDING;

  const isTrolleySuccessWithConflict =
    successfulTrolley && trolleyConflicts && trolleyConflicts.length > 0;

  const isTrolleySuccessNoConflict =
    successfulTrolley && trolleyConflicts && !trolleyConflicts.length;

  const defaultModalState = !successfulTrolley && trolleyStatus !== PENDING;

  const onCloseModal = () => {
    dispatch(clearTrolleyErrors());

    closeModal();
  };

  return (
    <Modal
      titleText="Meal Planner Add to Trolley"
      className={shoppableModalStyles.modal}
      contentClassName={shoppableModalStyles['modal-content']}
      isOpen={isOpen}
      onAfterOpen={() => {
        let i = 0;
        dataLayer.push({
          event: 'shop_recipes',
          products: Object.keys(shoppableProducts).reduce<object[]>((acc, recipeId) => {
            shoppableProducts[recipeId].forEach(group => {
              ['storeCupboard', 'nonStoreCupboard'].forEach(cupboard => {
                group.ingredients[cupboard as keyof IngredientGroups['ingredients']].forEach(
                  item => {
                    i += 1;
                    acc.push({
                      index: `${i}`,
                      sponsored: item.isSponsored ? 'YES' : 'NO',
                      sponsored_source: 'unknown',
                      lineNumber: item.lineNumber,
                      recipe_name: recipeId,
                    });
                  },
                );
              });
            });
            return acc;
          }, []),
        });
      }}
      handleModalClose={onCloseModal}
      cannotClose={isTrolleyPending || successfulTrolley}
    >
      {isTrolleyPending && (
        <div className={styles.loadingWrapper} data-testid="loading-modal">
          <Spinner ariaTextInactive="Loading finished" isActive={isTrolleyPending} size="large" />
          <Typography element="span" styleAs="sectionHeading" aria-live="polite" aria-atomic="true">
            Adding ingredients to your trolley
          </Typography>
        </div>
      )}

      {isTrolleySuccessWithConflict && (
        <div className={styles.successWrapper} data-testid="success-modal">
          <Warning className={styles.warningIcon} size="large" />
          <Typography element="span" styleAs="sectionHeading" aria-live="polite" aria-atomic="true">
            We&apos;re sorry, some items couldn&apos;t be added to your trolley.
          </Typography>
        </div>
      )}
      {isTrolleySuccessNoConflict && (
        <div className={styles.successWrapper} data-testid="success-modal">
          <SuccessSolid className={styles.successIcon} size="large" />
          <Typography element="span" styleAs="sectionHeading" aria-live="polite" aria-atomic="true">
            Ingredients added to your trolley
          </Typography>
        </div>
      )}
      {defaultModalState && (
        <div className={styles.content}>
          <div
            className={classNames(shoppableModalStyles.modalHeader, styles.modalHeader)}
            data-testid="add-to-trolley-modal"
          >
            <TypographySecondary component="h2" font="sebenta" size={16}>
              Shop meal plan recipes
            </TypographySecondary>
            <div className={shoppableModalStyles.servingInfo}>
              <span className={shoppableModalStyles.serves}>
                Recipes {numberOfSelectedRecipesWithShoppableProducts}/
                {numberOfRecipesWithShoppableProducts}
              </span>
              <span className={shoppableModalStyles.selected}>
                {numberOfSelectedShoppableProducts}/{numberOfShoppableProducts} ingredients selected
              </span>
            </div>
          </div>
          <div className={shoppableModalStyles.alerts}>
            <Alert type="info" title="Please check quantities">
              Some of the ingredients below may not show the correct amount needed, please adjust to
              suit
            </Alert>
            {trolleyConflicts &&
              trolleyConflicts.length > 0 &&
              trolleyConflicts.map(({ message, ingredient }) => {
                return (
                  <div
                    key={ingredient}
                    className={styles.trolleyConflict}
                    data-testid="trolley-conflict"
                  >
                    <Alert type="error" title={ingredient}>
                      {message}
                    </Alert>
                  </div>
                );
              })}
          </div>
          <RecipesAccordion
            recipes={recipes}
            selectedRecipesIds={selectedRecipesIds}
            setSelectedRecipesIds={setSelectedRecipesIds}
          />
          <div className={classNames(shoppableModalStyles.buttonWrapper, styles.buttonWrapper)}>
            <div className={styles.total}>
              <Typography component="span" styleAs="paragraph" className={styles.totalLabel}>
                Estimated Total
              </Typography>
              <Typography component="span" styleAs="paragraph" className={styles.totalPrice}>
                {formatAsPounds(recipesTotalPriceOfSelectedShoppableProducts)}
              </Typography>
            </div>
            <Button
              className={shoppableModalStyles.button}
              theme="finalising"
              startIcon={<TrolleyInactive />}
              onClick={addIngredientsToTrolley}
              disabled={numberOfSelectedShoppableProducts === 0}
            >
              Add selected items to trolley
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default MealPlannerAddToTrolleyModal;
