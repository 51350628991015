import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SummaryRequestParams } from 'services/recipes-landing-service';

import Filters from '../Content/Filters/Filters';

type Filters = {
  title: string;
  values: {
    name: string;
    count: number;
  }[];
}[];

export type FilterTitle = 'Serving Size' | 'Dietary' | 'Cuisine' | 'Total Time';
export const FilterTitles: [...FilterTitle[]] = [
  'Serving Size',
  'Dietary',
  'Cuisine',
  'Total Time',
];

export const getFiltersFromSearch = (searchParams: URLSearchParams) => {
  const filters: SummaryRequestParams['filters'] = [];

  searchParams.forEach((value, title) => {
    if (value && FilterTitles.includes(title as FilterTitle)) {
      filters.push({
        title,
        values: value.split(','),
      });
    }
  });

  return filters;
};

type CheckedState = boolean[][];

const paramIsFilter = (key: string) => FilterTitles.includes(key as FilterTitle);

export type QueryParamFiltersProps = {
  filters: Filters;
  onChange: (search: URLSearchParams) => void;
};

const QueryParamFilters = ({ filters, onChange }: QueryParamFiltersProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const activeFilters = getFiltersFromSearch(new URLSearchParams(location.search)).reduce<
    Record<string, string[]>
  >((acc, v) => {
    acc[v.title] = v.values;
    return acc;
  }, {});

  const checked = filters.reduce<CheckedState>(
    (acc, filter, i) => {
      if (activeFilters[filter.title]) {
        filter.values.forEach((v, j) => {
          if (activeFilters[filter.title].includes(v.name)) {
            acc[i][j] = true;
          }
        });
      }
      return acc;
    },
    Array.from({ length: filters.length }, () => []),
  );

  return (
    <Filters
      filters={filters}
      externalState={checked}
      showApplied={false}
      enableMobile={false}
      closedItems={['Cuisine', 'Total Time']}
      onChange={(checkedState: CheckedState) => {
        const search = new URLSearchParams(location.search);

        const toRemove: Array<string> = [];
        search.forEach((_, key) => {
          if (paramIsFilter(key)) toRemove.push(key);
        });
        toRemove.forEach(key => search.delete(key));

        filters.forEach(({ title, values }, f) => {
          const items = values.filter((_, i) => checkedState[f][i]);
          if (items.length) {
            search.set(title, items.map(v => v.name).toString());
          }
        });

        navigate({ pathname: location.pathname, search: search.toString() }, { replace: true });

        onChange(search);
      }}
    />
  );
};

export default QueryParamFilters;
export const { propTypes } = Filters; // eslint-disable-line react/forbid-foreign-prop-types
