import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Stars from '@johnlewispartnership/wtr-ingredients/ingredients/StarRating';
import styles from 'components/StarRating/StarRating.scss';

const StarRating = ({
  averageRating,
  clickHandler,
  hasSchema,
  isSizeSmall,
  lighter,
  linkTo,
  reviewCount,
}) => {
  const baseProps = {
    className: classNames(styles.starRating, {
      [styles.isSizeSmall]: isSizeSmall,
      [styles.lighter]: lighter,
    }),
  };

  const wrapperProps = {
    ...baseProps,
    to: linkTo,
    onClick: event => clickHandler(event),
  };

  const ratingText = isSizeSmall
    ? `(${reviewCount})`
    : `${reviewCount} ${reviewCount === 1 ? 'review' : 'reviews'}`;

  return (
    <Link {...wrapperProps} aria-label="Product Rating">
      <Stars
        rating={averageRating}
        hasSchema={hasSchema}
        starSize={isSizeSmall ? 'xsmall' : 'small'}
      />
      <span className={styles.ratingText}>{ratingText}</span>
    </Link>
  );
};

StarRating.displayName = 'StarRating';

StarRating.propTypes = {
  averageRating: PropTypes.number,
  clickHandler: PropTypes.func,
  hasSchema: PropTypes.bool,
  isSizeSmall: PropTypes.bool,
  lighter: PropTypes.bool,
  linkTo: PropTypes.string,
  reviewCount: PropTypes.number,
};

StarRating.defaultProps = {
  averageRating: 0,
  clickHandler: () => {},
  hasSchema: true,
  isSizeSmall: false,
  lighter: false,
  linkTo: undefined,
  reviewCount: 0,
};

export default memo(StarRating);
