import { DeliveryStatusResponse } from 'api/definitions/delivery-tracking';
import { deepFreeze } from 'utils/deepFreeze';

export interface DeliveryTrackingState {
  deliveryStatuses: Record<string, DeliveryStatusResponse>;
}
const initialState: Readonly<DeliveryTrackingState> = deepFreeze({
  deliveryStatuses: {},
});

export default initialState;
