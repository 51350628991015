import { FORMAT, dayjs } from 'utils/date';

export function formatSlotTime(time: string) {
  // In case time is an empty string (or null/undefined when called from js code)
  if (!time) return undefined;

  const isIsoTime = time.includes('T');
  const timeObj = isIsoTime ? dayjs(time).tz() : dayjs.tz(`2020-01-01T${time}`);
  const formattedTime = timeObj.format(FORMAT.HOUR_AMPM_UPPER);

  if (!isIsoTime && timeObj.hour() === 23 && timeObj.minute() === 30) return '12AM';

  return formattedTime;
}

/**
 * Formats the start/end slot time to be displayed.
 *
 * **If any of the values is null or undefined, an empty string will be returned**
 *
 * If time is at the top of the hour (e.g. 12:00) then minutes are not shown
 * Otherwise minutes are shown followed by am/pm
 *
 * @example
 * `7pm - 8pm`
 * `11am - 12:30pm`
 * `9am - 10am`
 */
export function formatSlotTimeRange(startTime: dayjs.ConfigType, endTime: dayjs.ConfigType) {
  if (!startTime || !endTime) {
    return '';
  }

  const startTimeUK = dayjs(startTime).tz();
  const endTimeUK = dayjs(endTime).tz();

  const start = startTimeUK.format(
    startTimeUK.minute() === 0 ? FORMAT.HOUR_AMPM : FORMAT.HOUR_MINUTE_AMPM,
  );
  const end = endTimeUK.format(
    endTimeUK.minute() === 0 ? FORMAT.HOUR_AMPM : FORMAT.HOUR_MINUTE_AMPM,
  );

  return `${start} - ${end}`;
}
