import createReducer from 'redux/createReducer';
import { GET_DELIVERY_STATUS } from 'redux/modules/orders/actions/types';
import { DeliveryStatusResponse } from 'api/definitions/delivery-tracking';
import { Action } from 'redux';
import { ApiError } from 'constants/errors';
import initialState, { DeliveryTrackingState } from './initial-state';

export const getDeliveryStatusLoading: WtrReducer<DeliveryTrackingState, undefined> = state => {
  return {
    ...state,
  };
};

export const getDeliveryStatusLoaded: ApiSuccessReducer<
  DeliveryTrackingState,
  Action,
  DeliveryStatusResponse
> = (state, action) => {
  const { deliveryStatuses, ...rest } = state;

  return {
    ...rest,
    deliveryStatuses: {
      ...deliveryStatuses,
      [action.payload.customerOrderId]: action.payload,
    },
  };
};

export const getDeliveryStatusFailed: ApiErrorReducer<
  DeliveryTrackingState,
  Action,
  ApiError
> = state => {
  return {
    ...state,
  };
};

const actionTypeReducerMap = [
  [GET_DELIVERY_STATUS.request, getDeliveryStatusLoading],
  [GET_DELIVERY_STATUS.success, getDeliveryStatusLoaded],
  [GET_DELIVERY_STATUS.failure, getDeliveryStatusFailed],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;

export default createReducer(initialState, actionTypeReducerMap);
