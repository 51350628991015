import { initialState } from 'redux/modules/sessions/reducer';

export const pending = state => ({
  ...state,
  loading: true,
  error: null,
});

export const fulfilled = (state, action) => {
  const { result } = action;

  return {
    ...state,
    ...result,
    accessToken: `Bearer ${result.accessToken}`,
    expiresIn: result.expires_in,
    loading: false,
    error: null,
  };
};

export const rejected = (state, action) => {
  const { error } = action;

  return {
    ...state,
    error,
    loading: false,
  };
};

export const clearError = state => ({
  ...state,
  error: null,
});

export const throttleLogins = state => ({
  ...state,
  error: 'THROTTLE_LOGIN_ERROR',
});

export const resetSessionState = state => ({
  ...state,
  ...initialState,
});

export const saveShoppingContext = (state, action) => ({
  ...state,
  ...action?.result?.data?.shoppingContext,
  loading: false,
  error: null,
});
