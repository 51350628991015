import createReducer from 'redux/createReducer';
import { deepFreeze } from 'utils/deepFreeze';
import { NO_DELIVERY_AND_COLLECTION, COLLECTION, DELIVERY } from '../actions/types';

export const initialState = deepFreeze({
  loading: false,
  error: null,
  id: null,
  created: null,
  isActive: false,
});

const savePayload = (state, { payload }) => ({
  ...state,
  ...payload,
});

const actionTypeReducerMap = new Map([
  [NO_DELIVERY_AND_COLLECTION, savePayload],
  [COLLECTION, savePayload],
  [DELIVERY, savePayload],
]);

export default createReducer(initialState, actionTypeReducerMap);
