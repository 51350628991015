import React, { memo } from 'react';
import classNames from 'classnames';
import { ChevronRightBold } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { useExperiments } from 'components/Experiment/useExperiments';
import { POD_WAS_PRICE } from 'constants/monetateExperiments';

import styles from 'components/Product/Promotion/Description/PromotionDescription.scss';

type PricePromotionDescriptionProps = {
  description: string;
  price: string;
  noMargin?: boolean;
  underlineText?: boolean;
  isLink?: boolean;
  backgroundColor?: string;
  withChevron?: boolean;
  isWMXOffer?: boolean;
};

const PricePromotionDescription = ({
  description,
  price,
  underlineText = false,
  noMargin = false,
  backgroundColor = undefined,
  isLink = false,
  withChevron = false,
  isWMXOffer = false,
}: PricePromotionDescriptionProps) => {
  const { getDecisionById } = useExperiments();
  const showStrikethrough =
    getDecisionById(POD_WAS_PRICE.experiment).variant === POD_WAS_PRICE.variant.showStrikethrough;

  return (
    <p
      className={classNames({
        [styles.noMargin]: noMargin,
      })}
    >
      <span
        className={classNames(styles.offerDescription, {
          [styles.withBackground]: !!backgroundColor,
          [styles.green]: backgroundColor === 'green',
          [styles.underline]: underlineText && !showStrikethrough,
        })}
        data-testid="offer-description"
      >
        <span>
          {`${description}${isWMXOffer ? '' : '.'}`}
          {!isWMXOffer && (
            <em
              className={classNames({ [styles.withStrikethrough]: showStrikethrough })}
              data-testid="was-price-description"
            >{` Was ${price}`}</em>
          )}
        </span>
        {backgroundColor && isLink && withChevron && (
          <ChevronRightBold size="xsmall" className={styles.chevronIcon} />
        )}
      </span>
    </p>
  );
};

PricePromotionDescription.displayName = 'PricePromotionDescription';

export default memo(PricePromotionDescription);
