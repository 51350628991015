import alternativesByIngredient from 'api/definitions/recipes/alternatives-by-ingredient';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';

import { GET_ORDER_PRODUCTS } from 'redux/modules/orders/actions/types';
import { fetchMultipleProducts } from 'redux/modules/products/actions/fetch-multiple-products';
import { log } from 'utils/error-logging';
import { PRODUCT_ALTERNATIVES_BY_INGREDIENT } from './types';

type AlternativesProps = {
  lineNumber: string;
  ingredientId: string;
  recipeId?: string;
};

type Params = AlternativesProps & { trolleyId: string };

export type AlternativeProduct = {
  mainIngredientPimsId: string;
  productTags: { name: string }[];
  isSponsored: boolean;
  weights: {
    uoms: string[];
    pricePerUomQualifier: string;
    perUomQualifier: string;
    sizeDescription: string;
    defaultQuantity: {
      amount: number;
      uom: string;
    };
  };
  id: string;
  name: string;
  lineNumber: string;
  matchStrategy: string;
  quantity: number;
  productType: string;
  images: {
    small: string;
    medium: string;
    large: string;
  };
  pricing: {
    currentSaleUnitPrice: any;
    displayPrice: string;
    displayPriceEstimated: boolean;
    displayPriceQualifier: string;
    displayUOMPrice: string;
    promotions: any[];
    currentSaleUnitRetailPrice: {
      quantity: {
        amount: number;
        uom: string;
      };
      price: {
        amount: number;
        currencyCode: string;
      };
    };
  };
};

export type AlternativeProducts = AlternativeProduct[];

const getAlternativesByIngredientAsync = (params: Params) => ({
  types: PRODUCT_ALTERNATIVES_BY_INGREDIENT.triplet,
  apiCall: alternativesByIngredient.post({
    body: params,
    iHandleStatusCodes: true,
  }),
});

export const getAlternativesByIngredient =
  ({
    lineNumber,
    ingredientId,
    recipeId,
  }: {
    lineNumber: string;
    ingredientId: string;
    recipeId?: string;
  }) =>
  async (dispatch: WtrDispatch, state: () => WtrState): Promise<AlternativeProducts | null> => {
    let trolleyId = getCustomerOrderId(state());
    if (parseInt(trolleyId, 10) === 0) {
      // fix for PIMS service receiving 0
      trolleyId = null;
    }

    // Fetch AlternativesByIngredient and then fetchMultipleProducts based on those lineNumbers
    try {
      const { alternativeProducts }: { alternativeProducts: AlternativeProducts } = await dispatch(
        getAlternativesByIngredientAsync({ lineNumber, ingredientId, trolleyId, recipeId }),
      );

      const lineNumbers = alternativeProducts
        .filter(item => item.lineNumber)
        .map(({ lineNumber: lineNum }) => lineNum);

      if (!lineNumbers.length) {
        return [];
      }

      const { products } = await dispatch(
        fetchMultipleProducts(GET_ORDER_PRODUCTS)(lineNumbers, {
          customerOrderId: trolleyId,
        }),
      );

      // Set mainIngredientId for each product
      const productsWithMainIngredientId = products.map((product: AlternativeProduct) => ({
        ...product,
        mainIngredientPimsId: ingredientId,
      }));

      return productsWithMainIngredientId;
    } catch (error) {
      log('Error fetching alternative shoppable products.', {
        logger: 'recipes',
        section: 'alternativeShoppableProducts',
      });
    }

    return null;
  };
