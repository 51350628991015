import productsByLinenumber from 'api/definitions/productsByLinenumber';

import { LEGACY_PRODUCTS_SCHEMA } from 'redux/schemas';
import { TRANSFORM_PRODUCTS_INTO_LEGACY_FORMAT } from 'redux/transforms';

export const fetchMultipleProducts =
  (types: readonly [string, string, string]) =>
  (
    lineNumbers: string[] = [],
    {
      listId,
      customerOrderId,
      iHandleStatusCodes,
      start,
      size,
    }: {
      listId?: string;
      customerOrderId?: string;
      iHandleStatusCodes?: boolean | number[];
      start?: number;
      size?: number;
    } = {},
  ) => ({
    types,
    apiCall: productsByLinenumber.get({
      lineNumbers: [...new Set(lineNumbers)].join('+'),
      trolleyId: customerOrderId,
      iHandleStatusCodes,
    }),
    lineNumbers,
    start,
    size,
    listId,
    schema: LEGACY_PRODUCTS_SCHEMA,
    transform: TRANSFORM_PRODUCTS_INTO_LEGACY_FORMAT,
  });
