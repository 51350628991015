import React, { memo } from 'react';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { SSRMediaQuery } from 'components/SSRMediaQuery';
import { BREAKPOINTS } from 'constants/grid';

import styles from './MoreFromThisBrandTitle.scss';

interface MoreFromThisBrandTitleProps {
  titleLine1: string;
  titleLine2: string;
  id: string;
}

const MoreFromThisBrandTitle = memo(
  ({ titleLine1, titleLine2, id }: MoreFromThisBrandTitleProps) => (
    <span className={styles.title} id={id}>
      <Typography
        className={styles.line1}
        element="span"
        noMargins
        styleAs="sectionHeadingSmall"
        fixedSize
      >
        {titleLine1}
      </Typography>
      <SSRMediaQuery maxWidth={BREAKPOINTS.lg} groupId="more-from-this-brand-max-lg">
        <Typography
          className={styles.line2}
          element="span"
          noMargins
          styleAs="screenHeadingSmall"
          fixedSize
        >
          {titleLine2}
        </Typography>
      </SSRMediaQuery>
      <SSRMediaQuery minWidth={BREAKPOINTS.lg} groupId="more-from-this-brand-min-lg">
        <Typography
          className={styles.line}
          element="span"
          noMargins
          styleAs="screenHeading"
          fixedSize
        >
          {titleLine2}
        </Typography>
      </SSRMediaQuery>
    </span>
  ),
);

MoreFromThisBrandTitle.displayName = 'MoreFromThisBrandTitle';

export default MoreFromThisBrandTitle;
