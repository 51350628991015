import React from 'react';
import { svgIcon } from 'utils/svg-icon';

const ForYouIcon = svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 33 32">
    <path d="M15.1 1.227a1.412 1.412 0 0 1 2.8 0c.92 6.948 6.425 12.453 13.373 13.374a1.412 1.412 0 0 1 0 2.798c-6.948.921-12.453 6.426-13.374 13.374a1.412 1.412 0 0 1-2.798 0C14.18 23.825 8.675 18.32 1.727 17.4a1.412 1.412 0 0 1 0-2.798C8.675 13.68 14.18 8.175 15.1 1.227Z" />
  </svg>,
  'ForYouIcon',
);

export default ForYouIcon;
