import { useCallback } from 'react';
import { useWtrSelector } from 'redux/hooks';
import { getScrollState } from 'redux/modules/scroll-session-storage/selectors';
import { setScrollStateInSessionStorage } from 'utils/scroll-session-storage';

export const useSetScrollState = (lineNumber: string) => {
  const scrollState = useWtrSelector(state => getScrollState(state));

  const setScrollState = useCallback(() => {
    setScrollStateInSessionStorage(scrollState, lineNumber);
  }, [scrollState, lineNumber]);

  return { setScrollState };
};
