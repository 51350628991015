import React from 'react';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import { dataLayer } from 'analytics/data-layer';
import { Filter } from 'services/recipes-landing-service';
import Filters, { FilterTitles } from './Filters';
import mealPlannerStyles from './MealPlannerGrid/index.scss';
import styles from './Preferences.scss';

interface PreferencesModal {
  isOpen: boolean;
  onClose: () => void;
  filters: Filter[];
  onFilterChange: (search: URLSearchParams) => void;
}

const PreferencesModal = ({ isOpen, onClose, filters, onFilterChange }: PreferencesModal) => {
  const onChange = (search: URLSearchParams) => {
    onFilterChange(search);

    dataLayer.push(
      FilterTitles.reduce<Record<string, string>>(
        (acc, title) => {
          acc[title.replace(/ /g, '_').toLowerCase()] = (search.get(title) || '').replace(',', '|');
          return acc;
        },
        { event: 'meal_planner_filter' },
      ),
    );
  };

  return (
    <Modal
      titleText="Filters"
      isOpen={isOpen}
      handleModalClose={onClose}
      className={mealPlannerStyles.modal}
      contentClassName={styles.content}
      buttons={[
        /*
        {
          buttonText: 'Clear all',
          theme: 'secondary',
          onClick: () => {
            navigate({ pathname: location.pathname, search: '' }, { replace: true });
            onChange(new URLSearchParams(''));
          },
        },
        */
        { buttonText: 'Apply filters', onClick: onClose },
      ]}
    >
      <Filters filters={filters} onChange={onChange} />
    </Modal>
  );
};

export default PreferencesModal;
