/* eslint-disable react/no-unused-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { getMinSuggestChars } from 'components/Search/utils/get-min-suggest-chars';

import styles from './Highlight.scss';

const HTML_REGEX = /(<([^>]+)>)/gi;

const Highlight = memo(({ match, string }) => {
  const cleanedString = string.replace(HTML_REGEX, '');
  const searchedWords = match.trim().split(/\s/);
  const suggestionsWords = cleanedString.split(/\s/);
  const minimumCharacters = getMinSuggestChars;

  return suggestionsWords.map(word => {
    const matchFound = searchedWords.find(
      sw => sw.length >= minimumCharacters && word.search(RegExp(sw, 'i')) !== -1,
    );

    if (matchFound) {
      const matchIndex = word.search(RegExp(matchFound, 'i'));

      return (
        <span key={`match-${word}`}>
          {word.slice(0, matchIndex)}
          <strong className={styles.highlight}>
            {word.slice(matchIndex, matchIndex + matchFound.length)}
          </strong>
          {word.slice(matchIndex + matchFound.length)}{' '}
        </span>
      );
    }

    return <span key={`nomatch-${word}`}>{word} </span>;
  });
});

Highlight.displayName = 'Highlight';

Highlight.propTypes = {
  match: PropTypes.string,
  string: PropTypes.string.isRequired,
};

Highlight.defaultProps = {
  match: '',
};

export default Highlight;
