import { SEARCH_TYPE } from '../constants';

export const isMealDealPage = (searchType: string) => searchType === SEARCH_TYPE;

export const makeMealDealProgressMsg = (
  productCountAndTotal: { totalCount: number; productCount: number } | null,
): string | null =>
  productCountAndTotal
    ? `${productCountAndTotal.productCount} of ${productCountAndTotal.totalCount} added`
    : null;
