import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleProductModal } from 'redux/modules/trolley/actions/toggle-product-modal';
import { getSwapUnavailableProductModal } from 'redux/modules/trolley/selectors/get-swap-unavailable-product-modal';
import { notifyModalClosed } from 'redux/modules/common-modal/actions';

import { viewAlternativesModal } from 'constants/modals';
import { BUILD_VIEW_ALTERNATIVES } from 'constants/monetateExperiments';

import SwapUnavailableProductModal from 'components/SwapUnavailableProductModal';
import { useExperiments } from 'components/Experiment/useExperiments';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';

const ViewAlternativesProductModal = () => {
  const dispatch: WtrDispatch = useDispatch();

  const { getDecisionById } = useExperiments();
  const isViewAlternativesModalVariant =
    getDecisionById(BUILD_VIEW_ALTERNATIVES.experiment).variant ===
    BUILD_VIEW_ALTERNATIVES.variant.toggleModal;

  const { modalId } = useSelector(getSwapUnavailableProductModal);

  const toggleAlternativesModal =
    isViewAlternativesModalVariant && modalId === viewAlternativesModal.id;

  const onCloseModal = useCallback(() => {
    dispatch(toggleProductModal());
    dispatch(
      notifyModalClosed({
        ...viewAlternativesModal,
        title: viewAlternativesModal.titleText,
      }),
    );
  }, [dispatch]);

  const ViewAlternativesModalButton = useCallback(
    () => (
      <Button
        data-testid="close-modal-btn"
        onClick={onCloseModal}
        label="Done"
        theme="finalising"
      />
    ),
    [onCloseModal],
  );

  return (
    <>
      {toggleAlternativesModal ? (
        <SwapUnavailableProductModal
          BodyButton={ViewAlternativesModalButton}
          onCloseModal={onCloseModal}
          isProductRemovable={false}
          titleText="VIEW ALTERNATIVES"
          subTitleText="Choose an alternative to add to your trolley"
          productPodAddText="Add"
        />
      ) : null}
    </>
  );
};

export default ViewAlternativesProductModal;
