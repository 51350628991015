import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { Close, Pen } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { Tooltip } from '@johnlewispartnership/wtr-ingredients/ingredients/Tooltip';
import ProceedThroughCheckout from 'components/ProceedThroughCheckout';
import { useIsBeforeYouGo } from 'hooks/use-is-beforeYouGo';
import { useWtrSelector } from 'redux/hooks';
import { getIsConfirmingInstantCheckout } from 'redux/modules/instant-checkout/selectors/status-selectors';
import { isSeasonalSlotDate, seasonalCutOffDateTime } from 'utils/checkout/is-seasonal-slot-date';
import { orderTypes } from 'utils/checkout/order-type';
import { dayjs, FORMAT } from 'utils/date';
import { formatAmendCutOffDateTime } from 'utils/format-amend-cutoff-date';
import { formatSlotTime } from 'utils/format-slot-time';

import styles from './AmendOrderAlert.scss';

function getAmendCutOffMessage(slotDate: string, amendOrderCutOff: string, orderType: string) {
  const isSeasonalSlot = isSeasonalSlotDate(slotDate);

  if (isSeasonalSlot) {
    return (
      <>
        Amend cut-off for Christmas Entertaining items is{' '}
        <span className={styles.cutOffDateTime}>{seasonalCutOffDateTime}</span>
      </>
    );
  }

  if (orderType === orderTypes.ENTERTAINING) {
    return <>Please check individual notice times before amending Entertaining orders</>;
  }

  if (orderType === orderTypes.GROCERIES_ENTERTAINING) {
    return <>Notice times vary for Entertaining items</>;
  }

  return (
    <>
      You can amend your order until{' '}
      <span className={styles.cutOffDateTime}>{formatAmendCutOffDateTime(amendOrderCutOff)}</span>
    </>
  );
}

export interface AmendOrderAlertProps {
  amendOrderCutOff: string;
  fulfilmentType: {
    confirmationPanel?: { headerText?: string };
  };
  isOpen?: boolean;
  onCancelChanges?: () => void;
  onToggle?: () => void;
  orderType: string;
  slotDate: string;
  slotEndTime: string;
  slotStartTime: string;
}

const AmendOrderAlert = ({
  amendOrderCutOff,
  fulfilmentType = {},
  isOpen = false,
  onCancelChanges,
  onToggle,
  orderType,
  slotDate,
  slotEndTime,
  slotStartTime,
}: AmendOrderAlertProps) => {
  const { confirmationPanel: { headerText: fulfilmentTypeMessage = '' } = {} } = fulfilmentType;
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);

  const isBeforeYouGo = useIsBeforeYouGo();

  const isConfirmingInstantCheckout = useWtrSelector(getIsConfirmingInstantCheckout);

  // close tooltip when model is opened
  useEffect(() => {
    if (isTooltipOpen && isConfirmingInstantCheckout) {
      setIsTooltipOpen(false);
    }
  }, [setIsTooltipOpen, isTooltipOpen, isConfirmingInstantCheckout]);

  // close tooltip when interacting with checkout button
  const handleCheckoutClick = useCallback(() => {
    setIsTooltipOpen(false);
  }, [setIsTooltipOpen]);

  const onExpandClick = () => {
    setIsTooltipOpen(false);
    onToggle?.();
  };

  if (
    !fulfilmentTypeMessage ||
    !slotDate ||
    !slotStartTime ||
    !slotEndTime ||
    !amendOrderCutOff ||
    !orderType
  ) {
    return null;
  }

  return (
    <div className={styles.amendOrderAlert} data-testid="amendOrderAlert">
      <section className={classNames(styles.titleSection, { [styles.open]: isOpen })}>
        <div className={styles.iconContainer}>
          <Pen />
          <Typography className={styles.amendIconTxt} styleAs="paragraphHeading">
            AMENDING
          </Typography>
        </div>

        <button
          data-testid="expand-bar-btn"
          aria-label="Expand"
          className={styles.expandChevron}
          onClick={onExpandClick}
          type="button"
        />
      </section>

      <section className={styles.details}>
        <p className={styles.slotInfo}>
          <span className={styles.slotDate} data-testid="slotDate">
            {`${fulfilmentTypeMessage} on ${dayjs(slotDate).format(FORMAT.LONG_DAY_MONTH)}`}
          </span>
          <span className={styles.slotTime} data-testid="slotTime">
            {` ${formatSlotTime(slotStartTime)} - ${formatSlotTime(slotEndTime)}`}
          </span>
        </p>
        <p>
          <span className={styles.amendOrderCutOff} data-testid="amendOrderCutOff">
            {getAmendCutOffMessage(slotDate, amendOrderCutOff, orderType)}
          </span>
        </p>
      </section>

      <section className={styles.ctas}>
        <ProceedThroughCheckout
          messagePlacement="topPopover"
          buttonCheckoutId={
            isBeforeYouGo
              ? 'checkout-amend-order-interstitials-alert'
              : 'checkout-amend-order-alert'
          }
          location="Amend snackbar"
          theme="primaryWhite"
          useLightTooltip
          handleClick={handleCheckoutClick}
        />

        <button
          className={styles.discard}
          data-testid="cancel-amendment-changes"
          onClick={onCancelChanges}
          type="button"
        >
          Discard amends
        </button>
      </section>
      <Tooltip
        content={
          <div className={styles.checkoutTooltipContent}>
            <Typography styleAs="paragraphSmall" className={styles.checkoutTooltipText}>
              Don&apos;t forget to checkout after
              <br />
              amending your order
            </Typography>
            <div>
              <Close
                size="xsmall"
                onClick={() => setIsTooltipOpen(!isTooltipOpen)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
        }
        placement="top-end"
        distanceOffset={25}
        skiddingOffset={0}
        disableFocusListener
        disableTouchListener
        disableHoverListener
        isOpen={isTooltipOpen && !isOpen}
      >
        <span aria-hidden className={styles.tooltipReference} />
      </Tooltip>
    </div>
  );
};

AmendOrderAlert.displayName = 'AmendOrderAlert';

export default AmendOrderAlert;
