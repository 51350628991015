import { createSelector, lruMemoize } from 'reselect';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { getProductById } from 'redux/modules/entities/selectors/products';
import { getPromotionById, getPromotions } from 'redux/modules/entities/selectors/promotions';

const EMPTY_ARRAY = [];

const getPromotionId = (_state, _productId, promotionId) => promotionId;

export const getAlternativeDisplayPrice = createSelector(
  [getProductById, getPromotionId],
  ({ previousPricing = {} } = {}, promotionId) => previousPricing[promotionId],
);

export const getProductCurrentSaleUnitPrice = createSelector([getProductById], product =>
  get(product, 'currentSaleUnitPrice', null),
);

export const makeGetProductDefaultUom = () =>
  createSelector([getProductById], product => product?.defaultQuantity?.uom ?? 'C62');

export const getProductDefaultUom = makeGetProductDefaultUom();

export const getProductDisplayPrice = createSelector([getProductById], product =>
  get(product, 'displayPrice', ''),
);

export const getProductFormattedPriceRange = createSelector([getProductById], product =>
  get(product, 'formattedPriceRange', ''),
);

export const makeGetProductPromotions = () =>
  createSelector([getProductById], product => get(product, 'promotions', EMPTY_ARRAY), {
    memoize: lruMemoize,
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  });

export const getProductPromotions = makeGetProductPromotions();

const getPromotionsByProductId = createSelector(
  [getPromotions, getProductPromotions],
  (promotions, promotionIds) => {
    const state = { entities: { promotions } };

    return promotionIds.length
      ? promotionIds.map(promotionId => getPromotionById(state, promotionId))
      : EMPTY_ARRAY;
  },
);

export const getMyWaitrosePromotion = createSelector([getPromotionsByProductId], promotions =>
  promotions.find(({ myWaitrosePromotion } = {}) => myWaitrosePromotion),
);
