import urls from 'constants/urls';

import { initiateSession } from 'redux/modules/sessions/actions/initiate-session';
import { getCustomerDataIfLoggedIn } from 'redux/modules/sessions/get-customer-data-if-logged-in';

import history from 'utils/history';

export const refreshSession = (dispatch, getState, { dataLoad } = { dataLoad: true }) => {
  const sessionGenerated = dispatch(initiateSession()).catch(err => {
    if (err) {
      const { error, status } = err;

      if (status === 403 && error === 'soft_locked') {
        history.replace(urls.forgotPasswordLocked);

        return;
      }

      if (err.status !== 401) throw err;
    }
  });

  const customerDataLoaded = sessionGenerated.then(() =>
    dataLoad ? getCustomerDataIfLoggedIn(dispatch, getState) : null,
  );

  return {
    customerDataLoaded,
    sessionGenerated,
  };
};
