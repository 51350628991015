import { Action } from 'redux';
import { RecipeSummaryItem, RecipeFilter } from 'api/definitions/recipes/index.d';
import { FilterTitles, FilterTitle } from 'components/Recipes/MealPlanner/Filters';
import { MealPlannerState } from '../index.d';
import {
  MEAL_PLANNER_LOADING,
  MEAL_PLANNER_SUCCESS,
  MEAL_PLANNER_FAILURE,
  MEAL_PLANNER_FILTERS_SUCCESS,
  MEAL_PLANNER_UPDATE,
  MEAL_PLANNER_CLEAR,
} from '../actions/types';

const DIETARY_FILTER_TITLE = 'Dietary';
const DIETARY_FILTER_MIN_COUNT = 10;

const initialState: MealPlannerState = Object.freeze({
  error: false,
  loading: true,
  recipes: new Array(7).fill(undefined),
  filters: [],
  dietaryRequirements: [],
  fewMatches: false,
});

const isType = <T>(x: T) => x;

type MealPlannerSuccessAction = Action & {
  result: {
    recipes: RecipeSummaryItem[];
    filters: RecipeFilter[];
  };
};

export type MealPlannerUpdateAction = Action & {
  recipes: RecipeSummaryItem[];
};

export default (
  state = initialState,
  action: Action | MealPlannerSuccessAction | MealPlannerUpdateAction,
) => {
  switch (action.type) {
    case MEAL_PLANNER_LOADING: {
      return isType<MealPlannerState>({
        ...state,
        loading: true,
      });
    }

    case MEAL_PLANNER_SUCCESS: {
      const result = (action as MealPlannerSuccessAction).result || {};

      return isType<MealPlannerState>({
        ...state,
        fewMatches: result.recipes.length < 4,
        recipes: result.recipes.concat(Array(7 - result.recipes.length).fill(undefined)),
        loading: false,
      });
    }

    case MEAL_PLANNER_FAILURE: {
      return isType<MealPlannerState>({
        ...state,
        error: true,
        loading: false,
      });
    }

    case MEAL_PLANNER_UPDATE: {
      return isType<MealPlannerState>({
        ...state,
        fewMatches: (action as MealPlannerUpdateAction).recipes.length < 4,
        recipes: (action as MealPlannerUpdateAction).recipes,
      });
    }

    case MEAL_PLANNER_CLEAR: {
      return isType<MealPlannerState>({
        ...state,
        loading: true,
        fewMatches: false,
        recipes: initialState.recipes,
      });
    }

    case MEAL_PLANNER_FILTERS_SUCCESS: {
      return isType<MealPlannerState>({
        ...state,
        filters: ((action as MealPlannerSuccessAction).result.filters || [])
          .filter(f => FilterTitles.includes(f.title as FilterTitle))
          .map(filter => ({
            ...filter,
            values:
              filter.title === DIETARY_FILTER_TITLE
                ? filter.values
                    .filter(value => value.count >= DIETARY_FILTER_MIN_COUNT)
                    .map(value => ({
                      ...value,
                      count: 0,
                    }))
                : filter.values.map(value => ({
                    ...value,
                    count: 0,
                  })),
          })),
      });
    }

    default:
      return state;
  }
};
