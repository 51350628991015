import get from 'lodash/get';
import { createSelector } from 'reselect';
import { RECIPE_CARDS_GRID } from '@johnlewispartnership/wtr-content-component-library/dist/component-library/constants/cmsResourceTypes';
import { WaitroseModelProps } from '@johnlewispartnership/wtr-content-component-library/dist/component-library/cms-components/types';
import env from 'env/env';
import { fifoBufferLookup } from 'utils/fifo-buffer';
import recipesBreadcrumbs from 'components/Recipes/utils/breadcrumbs';
import { RecipeLandingState, RecipeCardsGridInfo } from '../reducers';

type RecipesLandingRoot = {
  cmsHybridRecipesPage: RecipeLandingState;
};

const getSlice = (state: RecipesLandingRoot) => state.cmsHybridRecipesPage;

export const getRecipesByTags = (state: RecipesLandingRoot) =>
  get(state, 'cmsHybridRecipesPage.recipesByTags', []);

export const getBreadcrumbs = ({ cmsHybridRecipesPage }: RecipesLandingRoot) => {
  const breadcrumbs = recipesBreadcrumbs();
  if (cmsHybridRecipesPage.contentUrl === 'recipes') {
    breadcrumbs.breadcrumbs = []; // temporary breadcrumbs fix TODO: WPIP-37372
  }

  const cmsBreadcrumbs = [...(cmsHybridRecipesPage.breadcrumbs || [])];
  if (cmsBreadcrumbs.length)
    cmsBreadcrumbs.unshift({ name: 'All categories', path: `${env.root}/recipes/all-categories` });

  breadcrumbs.breadcrumbs.push(...cmsBreadcrumbs);

  return breadcrumbs;
};

export const getFilters = (state: RecipesLandingRoot) =>
  state.cmsHybridRecipesPage.filters.find(
    v => v[0] === state.cmsHybridRecipesPage.contentUrl,
  )?.[1] || [];

export const getMetaData = createSelector(getSlice, (state: RecipeLandingState) => {
  const { metaData } = state;
  return {
    ...metaData,
    keywords:
      metaData && metaData.keywords && metaData.keywords.length
        ? metaData.keywords.join(',')
        : null,
  };
});

export const getRecipeTags = (state: RecipesLandingRoot) =>
  state.cmsHybridRecipesPage?.recipeTags || [];

export const getTotalTaggedRecipes = (state: RecipesLandingRoot) =>
  // @ts-expect-error TODO
  parseInt(state.cmsHybridRecipesPage?.totalTaggedRecipes, 10) || 0;

export const getCurrentRecipesPage = (state: RecipesLandingRoot) =>
  // @ts-expect-error TODO
  parseInt(state.cmsHybridRecipesPage?.currentPage, 10) || 1;

export const getFilteringOperation = (state: RecipesLandingRoot) =>
  state.cmsHybridRecipesPage?.filteringOperation;

export const getPaginatingOperation = (state: RecipesLandingRoot) =>
  state.cmsHybridRecipesPage?.paginatingOperation;
export const getPageTitle = (state: RecipesLandingRoot) => state.cmsHybridRecipesPage.pageTitle;
export const getRecipeCardsGrid = ({ cmsHybridRecipesPage }: RecipesLandingRoot) => {
  // @ts-expect-error TODO
  return fifoBufferLookup(
    cmsHybridRecipesPage.locations,
    cmsHybridRecipesPage.contentUrl,
  )?.[0].childComponents?.find(
    (component: WaitroseModelProps) => component.resourceType === RECIPE_CARDS_GRID,
  ) as RecipeCardsGridInfo;
};
export const getFocusedRecipe = (state: RecipesLandingRoot) =>
  state.cmsHybridRecipesPage?.focusedRecipe;
export const getContentUrl = (state: RecipesLandingRoot) => state.cmsHybridRecipesPage?.contentUrl;
export const getCurrentPage = (state: RecipesLandingRoot) =>
  state.cmsHybridRecipesPage?.currentPage;
export const getLoadingCount = (state: RecipesLandingRoot) =>
  state.cmsHybridRecipesPage?.loadingCount;
export const getImage = (state: RecipesLandingRoot) => state.cmsHybridRecipesPage?.image;
export const getResponseCache = (state: RecipesLandingRoot) => state.cmsHybridRecipesPage.responses;
export const getLocations = ({ cmsHybridRecipesPage }: RecipesLandingRoot) =>
  fifoBufferLookup(cmsHybridRecipesPage.locations, cmsHybridRecipesPage.contentUrl);

export const getIsFirstPage = (state: RecipesLandingRoot) => getCurrentRecipesPage(state) === 1;
export const getIsLastPage = (state: RecipesLandingRoot) => {
  const totalTaggedRecipes = getTotalTaggedRecipes(state);
  const currentPage = getCurrentPage(state);
  const hasMorePages =
    totalTaggedRecipes > env.recipesPerPage &&
    totalTaggedRecipes - currentPage * env.recipesPerPage > 0;
  return !hasMorePages;
};
