import createReducer from 'redux/createReducer';
import type { LegacyProductReference } from 'constants/products';
import type { ForYouRequestState, ForYouSuccessState, ForYouState } from '../types';
import { GET_RECOMMENDATIONS } from '../actions/types';
import { initialState } from '../state';

type ProductsRecommendationsResult = {
  products: LegacyProductReference[];
};

export type ForYouSuccessAction = {
  result: ProductsRecommendationsResult;
};

const getForYouRequest = (state: ForYouState): ForYouRequestState => ({
  ...state,
  loading: true,
});

const getForYouSuccess = (
  _state: ForYouState,
  { result: { products } }: ForYouSuccessAction,
): ForYouSuccessState => ({
  loading: false,
  products,
});

const actionTypeReducerMap = [
  [GET_RECOMMENDATIONS.request, getForYouRequest],
  [GET_RECOMMENDATIONS.success, getForYouSuccess],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
