import React from 'react';
import urls from 'constants/urls';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import { Link } from 'react-router-dom';
import { storeLoginRedirect } from 'utils/login';
import locator from 'utils/locator';

type LoginLinkProps = React.ComponentProps<typeof TextLink>;

export const LoginLink: React.FC<LoginLinkProps> = ({ children, ...rest }) => {
  const handleClick = () => {
    storeLoginRedirect(locator.pathname + locator.search);
  };

  return (
    <TextLink component={Link} to={urls.login} onClick={handleClick} {...rest}>
      {children}
    </TextLink>
  );
};

export default LoginLink;
