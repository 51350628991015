import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

export const SESSION_DETAILS_LOADED = 'waitrose/sessions/SESSION_DETAILS_LOADED';
export const csrfTokenActionTypes = apiPromiseTypes('token', 'csrf');
export const tokenActionTypes = apiPromiseTypes('token', 'token');
export const tokenLogoutActionTypes = apiPromiseTypes('token', 'logout');
export const destroySessionsActionTypes = apiPromiseTypes('sessions', 'destroy');
export const shoppingContextActionTypes = apiPromiseTypes('shoppingContext', 'shoppingContext');
export const SET_ACCESS_TOKEN = 'waitrose/sessions/setAccessToken';
export const SIGNED_ON_AS_ANONYMOUS_CUSTOMER = 'waitrose/sessions/SIGNED_ON_AS_ANONYMOUS_CUSTOMER';
export const WAS_SIGNED_ON_AS_ANONYMOUS_CUSTOMER =
  'waitrose/sessions/WAS_SIGNED_ON_AS_ANONYMOUS_CUSTOMER';
export const SESSION_GLOBAL_RESET = 'waitrose/sessions/SESSION_GLOBAL_RESET';
export const THROTTLE_LOGINS = 'waitrose/sessions/THROTTLE_LOGINS';
export const DISABLE_THROTTLE_LOGINS = 'waitrose/sessions/DISABLE_THROTTLE_LOGINS';
export const SAVE_ITEM_TO_ADD_TO_TROLLEY_POST_AUTHENTICATION =
  'waitrose/sessions/SAVE_ITEM_TO_ADD_TO_TROLLEY_POST_AUTHENTICATION';
export const REMOVE_ITEM_TO_ADD_TO_TROLLEY_POST_AUTHENTICATION =
  'waitrose/sessions/REMOVE_ITEM_TO_ADD_TO_TROLLEY_POST_AUTHENTICATION';
export const SAVE_ITEM_TO_FAVOURITE_POST_AUTHENTICATION =
  'waitrose/sessions/SAVE_ITEM_TO_FAVOURITE_POST_AUTHENTICATION';
export const REMOVE_ITEM_TO_FAVOURITE_POST_AUTHENTICATION =
  'waitrose/sessions/REMOVE_ITEM_TO_FAVOURITE_POST_AUTHENTICATION';
export const CLEAR_ERROR = 'waitrose/sessions/CLEAR_ERROR';
