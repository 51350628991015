import history from 'utils/history';
import urls from 'constants/urls';
import locator from 'utils/locator';
import { stashObject, unstashObject } from 'utils/stash';
import { preserveStubsUrl } from 'utils/general';
import { usingStubs } from 'stubs';

export const onLoginPage = () => locator.pathname === urls.signIn;

export const loginPagePath = () =>
  `${urls.login}?redirect=${encodeURIComponent(locator.pathname + locator.search)}${
    usingStubs() ? '&usestubs' : ''
  }`;

export const getLoginRedirect = () => {
  return unstashObject('loginRedirect');
};

export const storeLoginRedirect = redirect => {
  stashObject('loginRedirect', redirect);
};

// use only in event handlers / effects
// in render phase use RedirectToLoginPage
export const redirectToLogin = () => {
  history.push(loginPagePath());
};

export const stubsLoginRedirect = email => {
  if (email === 'softLockedTestAccount@wtr-test.co.uk') {
    locator.href = preserveStubsUrl(`${urls.signIn}?error=SOFT_LOCKED`);
  } else if (email === 'hardLockedTestAccount@wtr-test.co.uk') {
    locator.href = preserveStubsUrl(`${urls.signIn}?error=HARD_LOCKED`);
  } else {
    let url = preserveStubsUrl(urls.groceriesHome);
    const redirect = new URLSearchParams(history.getLocation().search).get('redirect');

    if (redirect) {
      url = preserveStubsUrl(decodeURIComponent(redirect));
    }

    locator.href = `${url}${url.includes('?') ? '&' : '?'}usestubs`.replace(
      /stubflag=token-client.anonymous(:true)?&?/,
      '',
    );
  }
};
