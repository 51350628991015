import React from 'react';
import { svgIcon } from 'utils/svg-icon';
import { colourPrimaryGrey } from '@johnlewispartnership/wtr-ingredients/foundations/colours';

const OpenModalIcon = svgIcon(
  <svg aria-label="Opens in a pop-up" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill={colourPrimaryGrey}
      d="M12.93 16H0V3.475h6.869v1.212H1.212v10.1h10.505V9.334h1.212V16Z"
    />
    <path
      d="M14.8 1.2H10V6h4.8V1.2ZM8.8 0v7.2H16V0H8.8ZM2.956 12.143l4.009-3.99.846.85-4.009 3.99-.846-.85Z"
      fill={colourPrimaryGrey}
      fillRule="evenodd"
    />
    <path d="M7.988 7.972v4.578h-1.2V9.172H3.416v-1.2h4.572Z" fill={colourPrimaryGrey} />
  </svg>,
  'OpenModalIcon',
);

export default OpenModalIcon;
