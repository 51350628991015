export const MEAL_PLANNER_LOADING = 'waitrose/meal-planner/loading';
export const MEAL_PLANNER_SUCCESS = 'waitrose/meal-planner/success';
export const MEAL_PLANNER_FAILURE = 'waitrose/meal-planner/failure';

export const MEAL_PLANNER_FETCH_ACTIONS = [
  MEAL_PLANNER_LOADING,
  MEAL_PLANNER_SUCCESS,
  MEAL_PLANNER_FAILURE,
] as const;

const MEAL_PLANNER_FILTERS_LOADING = 'waitrose/meal-planner/filters/loading';
export const MEAL_PLANNER_FILTERS_SUCCESS = 'waitrose/meal-planner/filters/success';
const MEAL_PLANNER_FILTERS_FAILURE = 'waitrose/meal-planner/filters/failure';

export const MEAL_PLANNER_FETCH_FILTERS_ACTIONS = [
  MEAL_PLANNER_FILTERS_LOADING,
  MEAL_PLANNER_FILTERS_SUCCESS,
  MEAL_PLANNER_FILTERS_FAILURE,
] as const;

export const MEAL_PLANNER_UPDATE = 'waitrose/meal-planner/update';
export const MEAL_PLANNER_CLEAR = 'waitrose/meal-planner/clear';
