import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';
import { getSlotEndTime, getSlotStartTime } from 'redux/modules/orders/selectors/get-order';
import { createSelector } from 'reselect';

export const getDeliveryTrackingState = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'deliveryTracking');

export const getDeliveryStatuses = createSelector(
  getDeliveryTrackingState,
  trackingState => trackingState?.deliveryStatuses,
);

const getCustomerOrderIdFromArgs = (_: WtrState, customerOrderId: string) => customerOrderId;

export const getDeliveryInfoByOrderId = createSelector(
  getDeliveryStatuses,
  getCustomerOrderIdFromArgs,
  (deliveryStatuses, customerOrderId) => {
    return deliveryStatuses[customerOrderId] ? deliveryStatuses[customerOrderId] : undefined;
  },
);

export const getDeliveryStatusByOrderId = createSelector(
  getDeliveryInfoByOrderId,
  deliveryStatus => {
    return deliveryStatus ? deliveryStatus.status : undefined;
  },
);

export const getDisplaySlotTime = createSelector(
  getDeliveryStatuses,
  getSlotStartTime,
  getSlotEndTime,
  getCustomerOrderIdFromArgs,
  (deliveryStatuses, slotStartTime, slotEndTime, customerOrderId) => {
    const deliveryStatus = deliveryStatuses[customerOrderId];
    /**
     * slotStartTime/slotEndTime - originally booked timeslot (e.g. 4-hour Saver slot window)
     * etaStartTime/etaEndTime - estimated arrival time (e.g. estimated 1-hour slot sent in early morning)
     * wismoStartTime/wismoEndTime - more accurate real-time estimate
     */

    if (deliveryStatus?.wismoEtaStartDateTime && deliveryStatus?.wismoEtaEndDateTime) {
      return {
        startTime: deliveryStatus.wismoEtaStartDateTime,
        endTime: deliveryStatus.wismoEtaEndDateTime,
      };
    }
    if (deliveryStatus?.etaStartDateTime && deliveryStatus?.etaEndDateTime) {
      return {
        startTime: deliveryStatus.etaStartDateTime,
        endTime: deliveryStatus.etaEndDateTime,
      };
    }
    return {
      startTime: slotStartTime,
      endTime: slotEndTime,
    };
  },
);

export const getDriverNameByOrderId = createSelector(
  getDeliveryInfoByOrderId,
  getCustomerOrderIdFromArgs,
  deliveryStatus => {
    return deliveryStatus?.driverName;
  },
);
