import { connect } from 'react-redux';

import { GROCERIES_OFFERS, SEASONAL, SLOT_BUTTON } from 'constants/categoryIds';

import { getCategoryById, getCategoryBannerById } from 'redux/modules/taxonomy/selectors';
import { getMegaMenuMenus, getMegaMenuActiveLevel } from 'redux/modules/page/selectors/mega-menu';
import { getMegaMenuHistory, getPathForMegaMenuItemById } from 'redux/modules/mega-menu/selectors';
import { hasBookedSlot } from 'redux/modules/customer-slot/selectors';
import { getEditSlotUrl } from 'redux/modules/slot-booking/selectors/get-edit-slot-url';
import { getExperienceFragmentPageTitle } from 'redux/modules/experience-fragments/selectors';
import { SEASONAL_MENU } from 'constants/experience-fragments';

import MenuLink from './MenuLink';

export default connect((state, props) => {
  const { generated, id, maxMenus, offer = false } = props;
  let {
    emptyOfProducts = false,
    hiddenInNav = false,
    hasDescendants = false,
    iconName: icon,
    name,
    url,
  } = props;
  let isOffer = false;

  if (!generated) {
    const category = getCategoryById(state, id) || {};
    ({ emptyOfProducts } = category);
    ({ hasDescendants } = category);
    ({ hiddenInNav } = category);
    icon = category.iconName;
    isOffer = category.offer || false;
    ({ name } = category);
    ({ url } = category);
  }

  if (id === GROCERIES_OFFERS) {
    name = 'Groceries OFFERS';
  }

  if (id === SLOT_BUTTON && !hasBookedSlot(state)) {
    name = 'Book Slot';
  }

  if (id === SLOT_BUTTON && hasBookedSlot(state)) {
    icon = 'CalendarBooked';
    name = 'Change Slot';
    url = getEditSlotUrl(state);
  }

  if (id === SEASONAL) {
    name = getExperienceFragmentPageTitle(state, SEASONAL_MENU.key);
  }

  return {
    display: !emptyOfProducts && !hiddenInNav,
    hasDescendants: hasDescendants && getMegaMenuHistory(state).length < maxMenus,
    icon,
    name,
    menus: getMegaMenuMenus(state),
    level: getMegaMenuActiveLevel(state),
    offer: offer || isOffer,
    path: url ?? getPathForMegaMenuItemById(state, id),
    banner: getCategoryBannerById(state, id),
  };
})(MenuLink);
