/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useMemo, memo } from 'react';
import { useWtrSelector } from 'redux/hooks';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import Spinner from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';

import getIsLoadingSubs from 'redux/modules/trolley/selectors/get-loading-subs';
import { getSubProductsByLineNumber } from 'redux/modules/trolley/selectors/get-item-sub-products';

import SubProductPod from '../SubProductPod';

import styles from './index.scss';

const ProductsList = memo(({ emptySection, sortedArray, productPodAddText, largeThumbnails }) => {
  if (sortedArray.length > 0) {
    return (
      <ul className={styles.productsWrapper}>
        {sortedArray.map(({ lineNumber }) => {
          return (
            <SubProductPod
              key={lineNumber}
              largeThumbnails={largeThumbnails}
              lineNumber={lineNumber}
              productPodAddText={productPodAddText}
            />
          );
        })}
      </ul>
    );
  }

  return <div className={styles.emptySection}>{emptySection}</div>;
});

const SubsProductList = ({
  emptySection,
  headingText,
  largeThumbnails,
  lineNumber: subProductLineNumber,
  maxProducts,
  productPodAddText,
}) => {
  const substitutionProducts = useWtrSelector(state =>
    getSubProductsByLineNumber(state, subProductLineNumber),
  );
  const isLoadingSubs = useWtrSelector(getIsLoadingSubs);

  const sortedArray = useMemo(() => {
    return substitutionProducts
      .slice(0, maxProducts)
      .sort((itemA, itemB) => (itemA.score < itemB.score ? 1 : -1));
  }, [substitutionProducts, maxProducts]);

  return (
    <section>
      {headingText && (
        <header className={styles.header}>
          <Typography element="h2" styleAs="paragraphHeading">
            {headingText}
          </Typography>
        </header>
      )}
      {isLoadingSubs ? (
        <div className={styles.spinnerWrapper}>
          <Spinner size="large" isActive />
        </div>
      ) : (
        <ProductsList
          emptySection={emptySection}
          largeThumbnails={largeThumbnails}
          productPodAddText={productPodAddText}
          sortedArray={sortedArray}
        />
      )}
    </section>
  );
};

SubsProductList.displayName = 'SubsProductList';

SubsProductList.propTypes = {
  emptySection: PropTypes.node,
  headingText: PropTypes.string,
  largeThumbnails: PropTypes.bool,
  lineNumber: PropTypes.string.isRequired,
  maxProducts: PropTypes.number,
  productPodAddText: PropTypes.string,
};

SubsProductList.defaultProps = {
  emptySection: (
    <Typography styleAs="sectionHeading">
      Unfortunately we have no alternatives to show you
    </Typography>
  ),
  headingText: undefined,
  largeThumbnails: false,
  // As per UI team request we should display 10 item at most and for that reason it makes sense
  // to display the 10 item with the best score (most appropriate substitution products), highest score on top
  maxProducts: 10,
  productPodAddText: 'Select',
};

export default memo(SubsProductList);
