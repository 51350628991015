import React, { useCallback } from 'react';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { useWtrDispatch, useWtrSelector } from 'redux/hooks';
import { getMoreFromThisBrandPayloadByLineNumber } from 'redux/modules/experiments/selectors';
import { SEARCH_TYPE as FAVOURITES_SEARCH_TYPE } from 'components/Favourites/Page/constants';
import { useExperiments } from 'components/Experiment/useExperiments';
import { FAVOURITES_MORE_FROM_THIS_BRAND } from 'constants/monetateExperiments';
import OpenModalIcon from 'components/ProductPod/MoreFromThisBrandButton/OpenModalIcon';
import { getMoreFromThisBrandProducts } from 'redux/modules/products/actions/get-more-from-this-brand-products';
import extractLineNumberFromSku from 'utils/extract-linenumber-from-sku';
import { moreFromThisBrandModal } from 'constants/modals';
import { notifyModalOpen } from 'redux/modules/common-modal/actions';
import { toggleProductModal } from 'redux/modules/trolley/actions/toggle-product-modal';

import styles from './index.scss';

export interface MoreFromThisBrandButtonProps {
  productId?: string;
  searchType: string;
}

const MoreFromThisBrandButton = ({ productId, searchType }: MoreFromThisBrandButtonProps) => {
  const { getDecisionById } = useExperiments();
  const lineNumber = extractLineNumberFromSku(productId);

  const dispatch = useWtrDispatch();
  const moreFromThisBrandDetails = useWtrSelector(state =>
    getMoreFromThisBrandPayloadByLineNumber(state, lineNumber),
  );

  const isFavouritesPage = searchType === FAVOURITES_SEARCH_TYPE;
  const productInScopeForExperiment = !!moreFromThisBrandDetails && isFavouritesPage;
  const brandProductLineNumbers = moreFromThisBrandDetails?.lineNumbers;

  const handleButtonClick = useCallback(() => {
    dispatch(getMoreFromThisBrandProducts(lineNumber, brandProductLineNumbers)).catch(() => {});
    dispatch(
      notifyModalOpen({
        ...moreFromThisBrandModal,
        title: moreFromThisBrandModal.titleText,
      }),
    );
    dispatch(toggleProductModal(lineNumber, null, true, moreFromThisBrandModal.id));
  }, [brandProductLineNumbers, dispatch, lineNumber]);

  if (!productInScopeForExperiment) {
    return null;
  }

  const showMoreFromThisBrandButton =
    getDecisionById(FAVOURITES_MORE_FROM_THIS_BRAND.experiment).variant ===
    FAVOURITES_MORE_FROM_THIS_BRAND.variant.showMoreFromThisBrand;

  if (!showMoreFromThisBrandButton) {
    return null;
  }

  return (
    <TextLink
      className={styles.button}
      component="button"
      data-testid="more-from-this-brand-button"
      onClick={handleButtonClick}
      underline="always"
    >
      <Typography element="span" noMargins styleAs="paragraphHeading">
        More from this{' '}
        <span className={styles.brandTextWrap}>
          brand
          <OpenModalIcon className={styles.openModalIcon} />
        </span>
      </Typography>
    </TextLink>
  );
};

export default MoreFromThisBrandButton;
