import {
  FAVOURITES_MORE_FROM_THIS_BRAND,
  POD_SLIDEDOWN_ADVERT_GOES_WELL_WITH,
  POD_SLIDEDOWN_ADVERT_RECIPES,
  POD_SLIDEDOWN_ADVERT_RELATED,
} from 'constants/monetateExperiments';

const podSlidedownAdvertExperimentKeys = [
  POD_SLIDEDOWN_ADVERT_RECIPES.experiment,
  POD_SLIDEDOWN_ADVERT_RELATED.experiment,
  POD_SLIDEDOWN_ADVERT_GOES_WELL_WITH.experiment,
];

const formatPodSlidedownAdvert = payloads => {
  const productIdMap = {};

  podSlidedownAdvertExperimentKeys.forEach(experimentKey => {
    const payload = payloads?.[experimentKey] ?? {};

    Object.values(payload).forEach(({ productIds, ...variationMetadata }) => {
      productIds?.forEach(productId => {
        productIdMap[productId] = { experimentKey, ...variationMetadata };
      });
    });
  });

  return productIdMap;
};

const formatMoreFromThisBrand = payloads => {
  const payload = payloads?.[FAVOURITES_MORE_FROM_THIS_BRAND.experiment];

  if (!payload) {
    return {};
  }

  const lineNumberMap = {};

  Object.values(payload).forEach(({ lineNumbers, ...variationMetadata }) => {
    lineNumbers?.forEach(targetLineNumber => {
      lineNumberMap[targetLineNumber] = {
        lineNumbers: lineNumbers.filter(lineNumber => lineNumber !== targetLineNumber),
        ...variationMetadata,
      };
    });
  });

  return lineNumberMap;
};

export const saveExperiments = (
  state,
  { decisions, payloads, impressions, bypassMonetate, preview, ipAddress },
) => {
  const combinedPayloads = {
    ...state.payloads,
    ...payloads,
  };

  return {
    ...state,
    hasDecisions: true,
    decisions: {
      ...state.decisions,
      ...decisions,
    },
    payloads: {
      ...combinedPayloads,
      podSlidedownAdvert: formatPodSlidedownAdvert(combinedPayloads),
      [FAVOURITES_MORE_FROM_THIS_BRAND.experiment]: formatMoreFromThisBrand(combinedPayloads),
    },
    impressions: {
      ...state.impressions,
      ...impressions,
    },
    bypassMonetate: bypassMonetate ?? state.bypassMonetate,
    preview: preview ?? state.preview,
    ipAddress: ipAddress ?? state.ipAddress,
  };
};
