import { createSelector } from 'reselect';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const getSearch = ({ search } = EMPTY_OBJECT) => search;

export const getSearchCleared = createSelector(
  [getSearch],
  ({ searchCleared = false } = EMPTY_OBJECT) => searchCleared,
);

export const getSearchQueryParams = createSelector(
  [getSearch],
  ({ queryParams } = EMPTY_OBJECT) => queryParams,
);

export const getSearchCategory = createSelector(
  [getSearchQueryParams],
  ({ category } = EMPTY_OBJECT) => category,
);

export const getSearchFilterTags = createSelector(
  [getSearchQueryParams],
  ({ filterTags } = EMPTY_OBJECT) => filterTags ?? EMPTY_ARRAY,
);

export const getSearchTerm = createSelector(
  [getSearchQueryParams],
  ({ searchTerm } = '') => searchTerm,
);

export const getSearchOrigin = createSelector([getSearch], ({ origin = 'url' } = {}) => origin);

export const getSearchRulesets = createSelector(
  getSearch,
  ({ searchRulesets } = EMPTY_OBJECT) => searchRulesets,
);

// [BM]: guards against `searchRulesets` being null
export const getSearchRulesetsContainsEntry = createSelector(
  [getSearchRulesets, (state, entry) => entry],
  (searchRulesets = EMPTY_ARRAY, entry) =>
    searchRulesets && searchRulesets.some(searchRuleset => searchRuleset === entry),
);

export const getSearchWeightings = createSelector(
  getSearch,
  ({ relevancyWeightings } = EMPTY_OBJECT) => relevancyWeightings,
);

export const getSortByQueryParam = createSelector(
  getSearchQueryParams,
  ({ sortBy } = EMPTY_OBJECT) => sortBy,
);
