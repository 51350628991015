import React from 'react';

import DefaultCategoryLinks from 'components/CategoryLinks/DefaultCategoryLinks';
import CategoryCarousel from './CategoryCarousel';

import { CategoryLinksProps } from './types';

const useCategoryLinks = (isChristmasHub: boolean) => {
  let ComponentWithExperiment: React.FC<any> = DefaultCategoryLinks;
  let isDefault = true;

  if (isChristmasHub) {
    isDefault = false;
    ComponentWithExperiment = CategoryCarousel;
    return { ComponentWithExperiment, isDefault };
  }

  return { ComponentWithExperiment, isDefault };
};

const CategoryLinks = ({
  ariaLabel = undefined,
  isChristmasHub = false,
  categories = [],
  componentId = null,
  cqResponsiveClasses = [],
  hasSeeMore = true,
  hasTitleWithThroughLine = true,
  label = null,
  theme = 'grey',
  title,
}: CategoryLinksProps) => {
  const { ComponentWithExperiment, isDefault } = useCategoryLinks(isChristmasHub);

  if (!categories.length) return null;

  return (
    <>
      {isDefault ? (
        <ComponentWithExperiment
          ariaLabel={ariaLabel}
          categories={categories}
          componentId={componentId}
          cqResponsiveClasses={cqResponsiveClasses}
          hasSeeMore={hasSeeMore}
          hasTitleWithThroughLine={hasTitleWithThroughLine}
          label={label}
          theme={theme}
        />
      ) : (
        <ComponentWithExperiment categories={categories} title={title} />
      )}
    </>
  );
};

export default CategoryLinks;
