import { GET_MORE_FROM_THIS_BRAND_PRODUCTS } from 'redux/modules/products/actions/types';

import { fetchMultipleProducts } from 'redux/modules/products/actions/fetch-multiple-products';

const MAX_BRAND_PRODUCTS_SIZE = 16;

export const getMoreFromThisBrandProducts = (lineNumber: string, lineNumbers: string[]) => ({
  ...fetchMultipleProducts(GET_MORE_FROM_THIS_BRAND_PRODUCTS.triplet)(lineNumbers, {
    iHandleStatusCodes: true,
    size: MAX_BRAND_PRODUCTS_SIZE,
  }),
  lineNumber,
});
