import React, { useState, useRef, ReactNode, useEffect, useCallback } from 'react';
import { Action } from 'redux';
import { useDispatch, useSelector } from 'react-redux'; // eslint-disable-line @typescript-eslint/no-restricted-imports
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { Button } from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import { Snackbar } from '@johnlewispartnership/wtr-ingredients/ingredients/Snackbar';
import { Alert } from '@johnlewispartnership/wtr-ingredients/ingredients/Alert';
import {
  CircleAdd,
  RemoveOutline,
  Information,
  Close,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { Helmet } from 'react-helmet-async';
import { dataLayer } from 'analytics/data-layer';
import { Filter, filteredRecipes } from 'services/recipes-landing-service';
import { Recipe, RecipeSummaryItem } from 'api/definitions/recipes/index.d';
import { getAccessToken } from 'redux/modules/sessions/selectors';
import { DEFAULT_SORT_BY } from 'redux/modules/recipes/constants';
import { getRecipeById } from 'redux/modules/recipes/selectors/';
import { fetchRecipeById } from 'redux/modules/recipes/actions/get-recipe';
import { getProductsByRecipeId } from 'redux/modules/recipes/actions';
import { RecipesRoot, RecipeStateItem } from 'redux/modules/recipes/reducers';
import { mergeFilters } from 'redux/modules/cms-hybrid-recipes-page/reducers';
import { MEAL_PLANNER_UPDATE } from 'redux/modules/meal-planner/actions/types';
import { MealPlannerRoot, MealPlannerState } from 'redux/modules/meal-planner/index.d';
import { getMealPlanner } from 'redux/modules/meal-planner/selectors';
import { RecipeCard } from 'components/Recipes/Content/RecipeCard';
import { Tooltip } from '@johnlewispartnership/wtr-ingredients/ingredients/Tooltip';
import { local as storage } from 'utils/storage';
import root from 'window-or-global';
import { MEALPLANNER_PATH } from '../utils/path';
import { Fixed } from '../Fixed';
import MealPlannerAddToTrolleyModal from '../AddToTrolleyModal';
import { RecipeSelection } from '../RecipeSelection';
import { RecipeDetail } from '../../Content/RecipeDetail';
import { getFiltersFromSearch, FilterTitle, FilterTitles } from '../Filters';
import HowItWorksModal from '../HowItWorksModal';
import WaitroseLogo from '../../../WaitroseLogo';
import ServesModal from '../ServesModal';
import { getURLParamValue } from '../../../../redux/modules/recipes/utils/get-url-param-value';
import styles from './index.scss';

type DayOfWeek = 'Monday' | 'Tuesday';
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

type RecipeDay = {
  recipes: RecipeSummaryItem[];
  filters: Filter[];
  totalMatches: number;
  loading: boolean;
};

type PaginatedRecipes = [
  state: Record<Recipe['id'] | 'empty', RecipeDay>,
  loadMore: (
    recipe: Recipe['id'],
    exclude: Recipe['id'][],
    search?: URLSearchParams,
    clear?: boolean,
  ) => void,
  clear: (activeRecipe: Recipe['id']) => void,
];

const pageSize = 5;

const usePaginatedRecipes = (): PaginatedRecipes => {
  const [state, setState] = useState<Record<Recipe['id'] | 'empty', RecipeDay>>({});
  const location = useLocation();

  // Note that there is no access control on this request, so we could instead just send 'Bearer unauthenticated'.
  const jwt = useSelector(getAccessToken);

  const loadMore = (
    recipe: Recipe['id'] | DayOfWeek,
    exclude: Recipe['id'][],
    search?: URLSearchParams,
    clear?: boolean,
  ) => {
    const key = days.includes(recipe) ? 'empty' : recipe;
    const recipeData = state[key] || { recipes: [] };

    const filters = getFiltersFromSearch(search || new URLSearchParams(location.search));
    filters.push({ title: 'Course', values: ['Main meal'] });

    setState({
      ...state,
      [key]: {
        /* @ts-expect-error TODO */
        recipes: [],
        ...state[key],
        loading: true,
      },
    });

    filteredRecipes(
      jwt,
      [],
      filters,
      DEFAULT_SORT_BY,
      undefined,
      (!clear && recipeData?.recipes.length) || 0,
      pageSize,
      exclude,
      true,
      true,
    ).then(r =>
      setState({
        ...state,
        [key]: {
          recipes: (clear ? [] : recipeData.recipes).concat(r.recipes),
          filters: mergeFilters(
            state[key]?.filters,
            r.filters.filter(f => FilterTitles.includes(f.title as FilterTitle)),
          ),
          totalMatches: r.totalMatches,
          loading: false,
        },
      }),
    );
  };

  const clear = (activeRecipe: Recipe['id']) =>
    setState({
      [activeRecipe]: {
        recipes: [],
        filters: state[activeRecipe]?.filters,
        totalMatches: 0,
        loading: false,
      },
    });

  return [state, loadMore, clear];
};

const EmptyCard = ({
  day,
  children,
  onClick,
}: {
  day: string;
  children: ReactNode;
  onClick: () => void;
}) => (
  <li className={classnames(styles['empty-card'], styles.day, styles[day.toLowerCase()])}>
    <button type="button" onClick={onClick}>
      <span>Add recipe</span>
    </button>
    {children}
  </li>
);

const ConfirmRemoveCard = ({
  day,
  onYes,
  onNo,
}: {
  day: string;
  onYes: () => void;
  onNo: () => void;
}) => (
  <li className={classnames(styles['confirm-remove'], styles.day, styles[day.toLowerCase()])}>
    <div>
      Are you sure you want to remove this recipe from your meal plan?
      <button type="button" onClick={onYes}>
        Yes
      </button>
      <button type="button" onClick={onNo}>
        No
      </button>
    </div>
  </li>
);

const RecipeSnackbar = ({
  show,
  msg,
  onClose,
}: {
  show: boolean;
  msg: string;
  onClose: () => void;
}) => {
  return show ? (
    <Snackbar
      open
      placement="topRight"
      snapTo="parent"
      type="success"
      onClose={onClose}
      duration={4000}
      style={{ width: 'calc(100% - 32px)', minWidth: 150, marginTop: 36, zIndex: 1 }}
    >
      {msg}
    </Snackbar>
  ) : null;
};

interface MealPlannerGridProps {
  title?: string;
  subtitle?: string;
}

const { history } = root;

export const MealPlannerGrid = ({ title = 'Your meal plan', subtitle }: MealPlannerGridProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  const { recipes, filters, loading, fewMatches } = useSelector<MealPlannerRoot, MealPlannerState>(
    getMealPlanner,
  );
  const [trolleyModal, setTrolleyModal] = useState<boolean>(false);
  const [focusedRecipe, setFocusedRecipe] = useState<Recipe['id']>('');
  const [activeRecipe, setActiveRecipe] = useState<Recipe['id']>('');
  const [showRecipeDetail, setShowRecipeDetail] = useState<Recipe['id']>('');
  const [swapRecipes, loadMore, clearSwapRecipes] = usePaginatedRecipes();
  const recipeDetail = useSelector((state: RecipesRoot) => getRecipeById(showRecipeDetail)(state));
  const [[snackMsg, snackDay], setSnackMsg] = useState<[string, number]>(['', 0]);
  const [howItWorksModal, setHowItWorksModal] = useState<boolean>(false);
  const [confirmRemove, setConfirmRemove] = useState<Recipe['id']>('');
  const [tooltipStep, setTooltipStep] = useState<number>(0);
  const [tutorialComplete, setTutorialComplete] = useState<boolean>(
    storage.get('mealPlanTutorialComplete') === 'true',
  );
  const getRecipeDay = useCallback(() => {
    const day = swapRecipes[days.includes(activeRecipe) ? 'empty' : activeRecipe] || {
      recipes: [],
      filters: [],
      totalMatches: 0,
    };
    day.filters = mergeFilters(filters, day.filters).filter(
      value => !(!pathname.endsWith('meal-plans/builder') && value.title === 'Dietary'),
    );
    return day;
  }, [swapRecipes, activeRecipe, filters, pathname]);

  const servingSize = history?.state?.servingSize || getURLParamValue('Serving Size');
  const [showServesModal, setShowServesModal] = useState<boolean>(false);

  const recipeDay = getRecipeDay();
  const moreRecipesAvailable =
    !!recipeDay.recipes.length && recipeDay.recipes.length < recipeDay.totalMatches;

  const fetch = (search?: URLSearchParams, clear?: boolean) =>
    loadMore(
      activeRecipe,
      recipes.map(r => (r ? r.id : '')),
      search,
      clear,
    );

  const ratings = useRef<HTMLDetailsElement>(null);

  const summaryAsRecipe = (summary: RecipeSummaryItem | undefined): Partial<RecipeStateItem> => ({
    ...summary,
    nutritional: {},
  });

  const detailRecipe =
    (recipeDetail?.id && recipeDetail) ||
    summaryAsRecipe(
      recipes.find(r => r?.id === showRecipeDetail) ||
        swapRecipes[Object.keys(swapRecipes)[0]]?.recipes.find(r => r.id === showRecipeDetail),
    );

  const onSwap = (id: Recipe['id'] | undefined, updatedRecipes: MealPlannerState['recipes']) => {
    const wasEmpty = days.includes(activeRecipe);
    const index = wasEmpty
      ? days.indexOf(activeRecipe)
      : recipes.findIndex(r => r?.id === activeRecipe);

    setShowRecipeDetail('');
    setActiveRecipe('');
    clearSwapRecipes(activeRecipe); // recipe exclude-list has changed

    dispatch({
      type: MEAL_PLANNER_UPDATE,
      recipes: updatedRecipes,
    });

    /* @ts-expect-error TODO */
    dispatch(fetchRecipeById(id)).then(() => {
      dispatch(getProductsByRecipeId(id) as unknown as Action);
    });

    setSnackMsg([wasEmpty ? 'Recipe added to plan' : 'Recipe swapped successfully', index]);

    dataLayer.push({
      event: 'recipe_added',
      recipe_name: id,
      index,
      method: 'swapped',
    });
  };

  const handleNextStep = () => {
    if (tooltipStep === 1) {
      setTutorialComplete(true);
      storage.set('mealPlanTutorialComplete', 'true');
    } else {
      setTooltipStep(prev => prev + 1);
    }
  };

  const handleCloseTooltip = () => {
    setTutorialComplete(true);
    storage.set('mealPlanTutorialComplete', 'true');
  };

  useEffect(() => {
    if (servingSize || !pathname.includes(MEALPLANNER_PATH)) {
      return;
    }
    const timer = setTimeout(() => setShowServesModal(true), 2000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [servingSize, pathname]);

  const noRecipes = !recipes.some(Boolean);

  return (
    <>
      <Helmet bodyAttributes={{ class: 'meal-planner-builder-page' }} />
      <div className={classnames('no-print', 'container-fluid', styles.fluid)}>
        <Typography component="h1" styleAs="screenHeadingSmall" className={styles.h1}>
          {title}
        </Typography>
        {subtitle && (
          <Typography component="p" styleAs="paragraph" className={styles.subtitle}>
            {subtitle}
          </Typography>
        )}
        <div className={styles['how-it-works']}>
          <Button
            startIcon={<Information size="small" />}
            className={styles['how-it-works-button']}
            onClick={() => setHowItWorksModal(true)}
            width="fit"
            theme="secondary"
            small
          >
            How it works
          </Button>
        </div>
        <HowItWorksModal isOpen={howItWorksModal} closeModal={() => setHowItWorksModal(false)} />
        <ServesModal isOpen={showServesModal} closeModal={() => setShowServesModal(false)} />
        {fewMatches && (
          <Alert
            type="info"
            title="Few or no matches found"
            message="Try changing your serving size and dietary preferences by adding a recipe and editing your preferences"
            style={{ maxWidth: 638, margin: 'auto' }}
          />
        )}

        <ul className={styles.container}>
          {loading ||
            recipes.map((recipe, i) =>
              confirmRemove === recipe?.id ? ( // eslint-disable-line no-nested-ternary
                <ConfirmRemoveCard
                  day={days[i]}
                  key={days[i]}
                  onYes={() => {
                    dispatch({
                      type: MEAL_PLANNER_UPDATE,
                      recipes: recipes.map((r, day) => (day === i ? null : r)),
                    });

                    setConfirmRemove('');
                    setSnackMsg(['Recipe removed', i]);

                    dataLayer.push({
                      event: 'remove_recipe',
                      recipe_name: recipe.id,
                      index: i,
                    });
                  }}
                  onNo={() => setConfirmRemove('')}
                />
              ) : recipe ? (
                <RecipeCard
                  key={recipe.id}
                  className={classnames(styles.day, styles[days[i].toLowerCase()])}
                  recipe={recipe}
                  aria-label={days[i]}
                  headingLevel="h2"
                  analytics={{}}
                  onClick={e => {
                    setShowRecipeDetail(recipe.id);
                    setFocusedRecipe(recipe.id);
                    dispatch(fetchRecipeById(recipe.id) as unknown as Action);
                    e.preventDefault();
                  }}
                >
                  <Tooltip
                    className={classnames(styles.tutorialTooltip, styles.tutorialTooltipTopStart)}
                    placement="top-start"
                    distanceOffset={15}
                    skiddingOffset={8}
                    content={
                      <div className={styles.tutorialTooltipContentContainer}>
                        <div className={styles.tutorialTooltipContentRow}>
                          <Typography styleAs="paragraph" noMargins>
                            Use the swap icon to change a recipe.
                          </Typography>
                          <Close
                            size="small"
                            onClick={handleCloseTooltip}
                            className={styles.tutorialTooltipCloseButton}
                          />
                        </div>

                        <div className={styles.tutorialTooltipContentRow}>
                          <Typography
                            styleAs="paragraph"
                            noMargins
                            className={styles.tutorialTooltipStep}
                          >
                            1/2
                          </Typography>
                          <Button
                            theme="primaryWhite"
                            onClick={handleNextStep}
                            className={styles.tutorialTooltipButton}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    }
                    isOpen={!tutorialComplete && tooltipStep === 0 && i === 0}
                  >
                    <button
                      type="button"
                      className={classnames(styles.swap, styles['card-button'])}
                      title="Swap recipe"
                      onClick={() => {
                        setActiveRecipe(recipe.id);
                        setFocusedRecipe(recipe.id);
                        setSnackMsg(['', 0]);

                        dataLayer.push({
                          event: 'click_swap_recipe',
                          recipe_name: recipe.id,
                          index: i,
                        });
                      }}
                    />
                  </Tooltip>

                  <Tooltip
                    className={classnames(styles.tutorialTooltip, styles.tutorialTooltipTopEnd)}
                    placement="top-end"
                    distanceOffset={15}
                    skiddingOffset={-8}
                    content={
                      <div className={styles.tutorialTooltipContentContainer}>
                        <div className={styles.tutorialTooltipContentRow}>
                          <Typography styleAs="paragraph" noMargins>
                            Remove any recipe by clicking the (X) icon
                          </Typography>
                          <Close
                            size="small"
                            onClick={handleCloseTooltip}
                            className={styles.tutorialTooltipCloseButton}
                          />
                        </div>

                        <div className={styles.tutorialTooltipContentRow}>
                          <Typography styleAs="paragraph" noMargins>
                            2/2
                          </Typography>
                          <Button
                            theme="primaryWhite"
                            onClick={handleNextStep}
                            className={styles.tutorialTooltipButton}
                          >
                            Done
                          </Button>
                        </div>
                      </div>
                    }
                    isOpen={!tutorialComplete && tooltipStep === 1 && i === 0}
                  >
                    <button
                      type="button"
                      className={classnames(styles.remove, styles['card-button'])}
                      title="Remove recipe"
                      onClick={() => setConfirmRemove(recipe.id)}
                    />
                  </Tooltip>
                  <RecipeSnackbar
                    show={!!(snackMsg && i === snackDay)}
                    msg={snackMsg}
                    onClose={() => setSnackMsg(['', i])}
                  />
                </RecipeCard>
              ) : (
                <EmptyCard
                  day={days[i]}
                  key={days[i]}
                  onClick={() => {
                    setActiveRecipe(days[i]);
                    setSnackMsg(['', 0]);
                    dataLayer.push({
                      event: 'click_add_recipe',
                      index: i,
                    });
                  }}
                >
                  <RecipeSnackbar
                    show={!!(snackMsg && i === snackDay)}
                    msg={snackMsg}
                    onClose={() => setSnackMsg(['', i])}
                  />
                </EmptyCard>
              ),
            )}
        </ul>
      </div>
      <Fixed>
        <Button theme="finalising" onClick={() => setTrolleyModal(true)} disabled={noRecipes}>
          Order Online
        </Button>
        {trolleyModal && (
          <MealPlannerAddToTrolleyModal
            recipes={recipes.filter(Boolean)}
            isOpen={trolleyModal}
            closeModal={() => setTrolleyModal(false)}
          />
        )}
      </Fixed>
      <Modal
        className={classnames(styles.modal, styles['swap-modal'])}
        contentClassName={styles['swap-modal-content']}
        bodyOpenClassName={styles['modal-body']}
        isOpen={!!activeRecipe.length}
        handleModalClose={() => setActiveRecipe('')}
        onAfterOpen={() => {
          if (!swapRecipes[activeRecipe] || !swapRecipes[activeRecipe].recipes.length) {
            fetch();
          }
        }}
      >
        <RecipeSelection
          recipes={recipeDay.recipes}
          filters={recipeDay.filters}
          loading={recipeDay.loading}
          moreRecipesAvailable={moreRecipesAvailable}
          onFilterChange={search => {
            clearSwapRecipes(activeRecipe);
            fetch(search, true);
          }}
          onSelect={id => {
            const dayRecipes = getRecipeDay().recipes;
            onSwap(
              id,
              recipes.map((recipe, day) => {
                return [recipe?.id, days[day]].includes(activeRecipe)
                  ? dayRecipes.find(r => r.id === id) || null
                  : recipe;
              }),
            );
          }}
          onView={recipeId => {
            setShowRecipeDetail(recipeId);
            dispatch(fetchRecipeById(recipeId) as unknown as Action);
          }}
          loadMore={() => fetch()}
        />
      </Modal>
      <Modal
        titleText="Recipe preview"
        isOpen={!!showRecipeDetail}
        handleModalClose={() => setShowRecipeDetail('')}
        className={styles['recipe-modal']}
        contentClassName={styles['recipe-modal-content']}
      >
        <WaitroseLogo small className={styles['waitrose-logo']} />
        <RecipeDetail
          recipe={detailRecipe as Recipe}
          loading={false}
          headingLevel={2}
          showPrint
          rating={detailRecipe.rating}
          ratings={ratings}
        />
        <div className={classnames('no-print', styles['sticky-button'])}>
          {detailRecipe.id === focusedRecipe ? (
            <Button
              theme="secondary"
              width="full"
              className={styles['add-recipe']}
              onClick={() => {
                setShowRecipeDetail('');
                dispatch({
                  type: MEAL_PLANNER_UPDATE,
                  recipes: recipes.map(recipe => (recipe?.id === detailRecipe.id ? null : recipe)),
                });
                setSnackMsg(['Recipe removed', recipes.findIndex(r => r?.id === detailRecipe.id)]);
              }}
              startIcon={<RemoveOutline />}
            >
              Remove recipe
            </Button>
          ) : (
            <Button
              theme="primary"
              width="full"
              className={styles['add-recipe']}
              onClick={() =>
                onSwap(
                  detailRecipe.id,
                  recipes.map(recipe =>
                    recipe?.id === activeRecipe
                      ? swapRecipes[activeRecipe].recipes.find(r => r.id === detailRecipe.id) ||
                        null
                      : recipe,
                  ),
                )
              }
              startIcon={<CircleAdd />}
            >
              Add recipe
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default MealPlannerGrid;
