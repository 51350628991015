import React, { memo } from 'react';
import { useWtrSelector } from 'redux/hooks';
import { getProductById } from 'redux/modules/entities/selectors/products';
import isEqual from 'lodash/isEqual';

import {
  brandPriceMatchPromotion,
  featuredPromotion,
  introductoryOfferPromotion,
  percentageOffPromotion,
} from 'utils/promotion-types';
import { formatAsPositivePoundsOrPence } from 'utils/currency';
import { WMX_PROMOTION_TYPE_CODE } from 'redux/modules/entities/selectors/promotions/constants';

import IntroductoryOffer from 'components/Product/Promotion/Description/IntroductoryOffer';
import PricePromotion from 'components/Product/Promotion/Description/PricePromotion';
import SimpleDescription from 'components/Product/Promotion/Description/Simple';
import PriceOnlyDescription from 'components/Product/Promotion/Description/PriceOnlyDescription';

type PromotionDescriptionProps = {
  alternatePrice?: string;
  miniPromo?: boolean;
  noMargin?: boolean;
  productId: string;
  promotion: {
    myWaitrosePromotion: boolean;
    promotionDescription: string;
    promotionExpiryDate: string;
    promotionId: number;
    promotionTypeCode: string;
    wasDisplayPrice: string;
  };
  underlineText?: boolean;
  backgroundColor?: string;
  isLink?: boolean;
  isTrolley?: boolean;
  withChevron?: boolean;
};

const PromotionDescription = ({
  alternatePrice = undefined,
  miniPromo = false,
  noMargin = false,
  productId,
  promotion,
  underlineText = true,
  backgroundColor = undefined,
  withChevron = false,
  isLink = false,
  isTrolley = false,
}: PromotionDescriptionProps) => {
  const currentSaleUnitPrice = useWtrSelector(
    // @ts-expect-error: createSelector known issue, where the return function is typed as receiving a single arg
    state => getProductById(state, productId)?.currentSaleUnitPrice,
    isEqual,
  );
  const { promotionDescription: description, promotionTypeCode } = promotion;
  const { price: { amount = 0 } = {} } = currentSaleUnitPrice;
  const price = alternatePrice || (amount && formatAsPositivePoundsOrPence(amount)) || null;

  if (!description) return null;

  if (introductoryOfferPromotion(promotionTypeCode)) {
    return <IntroductoryOffer {...{ price, underlineText }} />;
  }

  if (brandPriceMatchPromotion(promotionTypeCode)) {
    return (
      <SimpleDescription
        {...{
          description: miniPromo ? 'Brand Price Match' : description,
          underlineText,
          noMargin,
        }}
      />
    );
  }

  if (featuredPromotion(promotionTypeCode) || percentageOffPromotion(promotionTypeCode)) {
    return (
      <SimpleDescription
        {...{ description, underlineText, noMargin, backgroundColor, isLink, withChevron }}
      />
    );
  }

  if (promotionTypeCode === 'NLP' && !isTrolley) {
    return (
      <PriceOnlyDescription
        {...{ underlineText, price, noMargin, backgroundColor, isLink, withChevron }}
      />
    );
  }

  return (
    <PricePromotion
      {...{
        description,
        price,
        underlineText,
        noMargin,
        backgroundColor,
        isLink,
        withChevron,
        isWMXOffer: promotionTypeCode === WMX_PROMOTION_TYPE_CODE,
      }}
    />
  );
};

PromotionDescription.displayName = 'PromotionDescription';

PromotionDescription.defaultProps = {
  alternatePrice: undefined,
  noMargin: false,
  miniPromo: false,
  underlineText: true,
  isLink: false,
  isTrolley: false,
  backgroundColor: undefined,
  withChevron: false,
};

export default memo(PromotionDescription);
