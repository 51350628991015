import React, { type ComponentType } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import { Head } from 'components/App/Head';
import { CookiesScripts } from 'components/CookieAlert/CookiesScripts';
import DataProvider from 'components/DataProvider';
import ErrorBoundary from 'components/ErrorBoundary';
import ExperimentsReporter from 'components/Experiment/ExperimentsReporter';
import { CheckoutWrapper } from 'components/Checkout/CheckoutWrapper';
import RefreshMessaging from 'components/RefreshMessaging';
import { SimpleErrorBoundary } from 'components/SimpleErrorBoundary/SimpleErrorBoundary';
import SiteFooterMinimal from 'components/Footer/SiteFooterMinimal';
import SiteHeaderMinimal from 'components/SiteHeader/SiteHeaderMinimal';
import { loadable } from 'components/Skeleton/LoadableSkeleton'; // This HAS to be called `loadable` to work with SSR
import WebVitalsReporter from 'components/WebVitalsReporter';
import { withBlockingApis } from 'components/withBlockingApis';
import urls from 'constants/urls';
import { hasCustomerSlotInitialLoadCompleted } from 'redux/modules/customer-slot/selectors';
import { hasGetMembershipStatusInitialLoadCompleted } from 'redux/modules/marketing-preferences/selectors/get-membership-status';
import hasTrolleyLoadCompleted from 'redux/modules/trolley/selectors/get-loaded';

import styles from './App.scss';

const withLegacyBlockingApis = (
  WrappedComponent: ComponentType,
  fallbackOverride?: JSX.Element | null,
) =>
  withBlockingApis(
    WrappedComponent,
    [
      hasCustomerSlotInitialLoadCompleted,
      hasGetMembershipStatusInitialLoadCompleted,
      hasTrolleyLoadCompleted,
    ],
    fallbackOverride,
  );

const noFallback = {
  fallback: null as never,
};

const CommonModal = loadable(
  () =>
    import(
      /* webpackChunkName: "CommonModal" */
      'components/Modal/CommonModal'
    ),
  noFallback,
);

const CommonSnackbar = loadable(
  () =>
    import(
      /* webpackChunkName: "CommonSnackbar" */
      'components/CommonSnackbar'
    ),
  noFallback,
);

const Checkout = loadable(
  () =>
    import(
      /* webpackChunkName: "checkout" */
      'components/Checkout/CheckoutLoader'
    ),
  { fallback: <CheckoutWrapper /> },
);

const ResolveOrderPayment = loadable(() => import('components/ResolveOrderPayment'), {
  fallback: <CheckoutWrapper title="Payment" />,
});

const OrderConfirmation = withLegacyBlockingApis(
  loadable(() => import('components/OrderConfirmation')),
);

const ForgotPassword = withLegacyBlockingApis(loadable(() => import('components/ForgotPassword')));
const Login = withLegacyBlockingApis(loadable(() => import('components/Login')));
const Registration = withLegacyBlockingApis(loadable(() => import('components/Registration')));

export interface WebviewAppProps {
  location: Pick<Location, 'pathname'>;
  minimalPage?: boolean;
}

export function WebviewApp({ location, minimalPage }: WebviewAppProps) {
  const { pathname } = location;
  const pagesWithHeaderInWebview = [urls.forgotPassword, urls.signIn];
  const showHeaderInWebview = pagesWithHeaderInWebview.find(url => pathname.includes(url));
  const pagesWithFooterInWebview = [
    urls.forgotPassword,
    urls.registration,
    urls.registrationAccountDetails,
    urls.signIn,
  ];
  const showFooterInWebview = pagesWithFooterInWebview.find(url => pathname.includes(url));

  return (
    <>
      <WebVitalsReporter />
      <ExperimentsReporter />
      {/* Calls the route loaders */}
      <DataProvider />
      {/* Renders the scripts related to cookies, if enabled */}
      <CookiesScripts />
      <Head />
      <SimpleErrorBoundary
        logger="App"
        section="top-level-error-boundary"
        errorComponent={<RefreshMessaging />}
      >
        <Routes>
          <Route
            path={urls.checkout}
            element={
              <ErrorBoundary logger="checkout-component" section="checkout">
                <Checkout />
              </ErrorBoundary>
            }
          />
          <Route path={urls.resolveOrderPayment} element={<ResolveOrderPayment />} />
          <Route
            element={
              <div className={minimalPage ? styles.appWrapperMinimal : undefined}>
                {showHeaderInWebview && <SiteHeaderMinimal />}
                <main
                  className={minimalPage ? styles.appMainMinimal : styles.appMain}
                  role="main"
                  id="main"
                >
                  <SimpleErrorBoundary
                    logger="App"
                    section="main-section-error-boundary"
                    errorComponent={<RefreshMessaging />}
                  >
                    <Outlet />
                  </SimpleErrorBoundary>
                </main>
                {showFooterInWebview && <SiteFooterMinimal />}
              </div>
            }
          >
            <Route
              path={`${urls.orderConfirmation}/:customerOrderId/*`}
              element={<OrderConfirmation />}
            />
            <Route path={`${urls.forgotPassword}/*`} element={<ForgotPassword />} />
            <Route path={urls.signIn} element={<Login />} />
            <Route path={`${urls.registration}/*`} element={<Registration />} />
          </Route>
        </Routes>
      </SimpleErrorBoundary>
      <CommonModal />
      <CommonSnackbar />
    </>
  );
}
