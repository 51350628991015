import env from 'env/env';
import token from 'api/definitions/tokenClient';
import resetSession from 'redux/modules/sessions/actions/reset-session';
import { sessionDetailsLoaded } from 'redux/modules/sessions/actions/session-details-loaded';
import { usingStubs } from 'stubs';
import { removeCookie } from 'utils/cookie';
import { axios } from 'utils/request';
import { COOKIE_CUSTOMER_ID } from 'constants/cookies';
import { csrfTokenActionTypes, tokenLogoutActionTypes } from './types';

const getTokenClientCsrf = () => ({
  types: csrfTokenActionTypes.triplet,
  apiCall: token.csrf(),
});

const tokenClientLogout = (csrfToken, headerName) => ({
  types: tokenLogoutActionTypes.triplet,
  apiCall: token.logout({
    headers: {
      [headerName]: csrfToken,
    },
    iHandleStatusCodes: [401, 403],
  }),
});

const getAuthCsrf = async () => {
  const response = await axios({
    method: 'get',
    url: `${env.authDomain}/v1/csrf`,
    withCredentials: true,
  });
  return response.data;
};

const authLogout = async (authCsrfParameter, authCsrfToken) => {
  await axios({
    method: 'post',
    url: `${env.authDomain}/v1/logout`,
    withCredentials: true,
    data: {
      [authCsrfParameter]: authCsrfToken,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

const waitAndRetryBeforeThrowing = async (logout, retry = 1) => {
  try {
    return await logout();
  } catch (error) {
    if (retry) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      return waitAndRetryBeforeThrowing(logout, retry - 1);
    }
    throw error;
  }
};

export const destroySession =
  (returningToAdmin = false) =>
  async dispatch => {
    try {
      if (!usingStubs()) {
        await waitAndRetryBeforeThrowing(async () => {
          const { parameterName: authCsrfParameter, token: authCsrfToken } = await getAuthCsrf();
          await authLogout(authCsrfParameter, authCsrfToken);
        });
      }

      await waitAndRetryBeforeThrowing(async () => {
        const { headerName: tokenClientCsrfHeaderName, token: tokenClientCsrfToken } =
          await dispatch(getTokenClientCsrf());
        await dispatch(tokenClientLogout(tokenClientCsrfToken, tokenClientCsrfHeaderName));
      });

      removeCookie(COOKIE_CUSTOMER_ID);

      if (!returningToAdmin) {
        dispatch(resetSession());
        dispatch(sessionDetailsLoaded());
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(new Error('Error logging out'));
    }
  };
