import React, { useCallback, useRef } from 'react';
import { useWtrSelector, useWtrDispatch } from 'redux/hooks';
import { useLocation } from 'react-router-dom';

import {
  getAppliedFilters,
  getSearchAndBrowseLoading,
} from 'redux/modules/search-and-browse/selectors';
import { getFilterGroups } from 'redux/modules/search-and-browse/selectors/get-filter-groups';

import { applyFilter } from 'redux/modules/search/actions/apply-filter';
import { clearFilters } from 'redux/modules/search/actions/clear-filters';
import { push } from 'redux-first-history';

import type { Groups } from 'components/Search/Filters/types';

import { KEY_ENTER } from 'constants/keys';
import { mergeFilterParams } from 'utils/searchQuery';

import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import { RemoveSolid } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import FilterCombos from 'components/Search/Filters/FilterCombos';
import FiltersMobile from 'components/Search/Filters/FiltersMobile';
import { SSRMediaQuery } from 'components/SSRMediaQuery';
import { BREAKPOINTS } from 'constants/grid';
import OfferToggle from 'components/Search/Filters/OfferToggle';
import { yieldToMain } from 'utils/yield-to-main';

import styles from 'components/Search/Filters/FilterForm/FilterForm.scss';

type FilterFormProps = {
  hideCategoryFilter: boolean;
  hideOfferType: boolean;
  onChange: () => void;
  stickyFilter: boolean;
  forceOfferToggle: boolean;
};

const FilterForm = ({
  hideCategoryFilter,
  hideOfferType,
  onChange,
  stickyFilter,
  forceOfferToggle,
}: FilterFormProps) => {
  const filterFormRef = useRef<HTMLElement>(null);

  const location = useLocation();
  const dispatch = useWtrDispatch();

  const appliedFilters: [{ group: string; id: string; text: string }] = useWtrSelector(state =>
    // @ts-expect-error: createSelector known issue, where the return function is typed as receiving a single arg
    getAppliedFilters(state, { hideOfferType }),
  );
  // @ts-expect-error: createSelector known issue, where the return function is typed as receiving a single arg
  const groups = useWtrSelector(state => getFilterGroups(state, { hideCategoryFilter }));
  const isLoading = useWtrSelector(getSearchAndBrowseLoading);

  const filterAction = useCallback(
    (action, ...args) => {
      const { pathname, search } = location;

      if (isLoading) return;

      const filterQueryString = dispatch(action(...args));
      // @ts-expect-error: args typed incorrectly until `src/utils/searchQuery.js` is converted to typescript
      const nextQueryString = mergeFilterParams(search, filterQueryString);

      dispatch(push(`${pathname}${nextQueryString}`, { noScroll: true, refinement: true }));

      onChange?.();
    },
    [dispatch, isLoading, location, onChange],
  );

  const changeFilter = useCallback(
    async ({ name, allowMultiple }, value) => {
      if (value) {
        await yieldToMain();
        filterAction(applyFilter, name, value, allowMultiple, stickyFilter, forceOfferToggle);
      } else {
        filterAction(clearFilters, name);
      }
    },
    [filterAction, forceOfferToggle, stickyFilter],
  );

  const clearFiltersAction = useCallback(() => {
    filterAction(clearFilters);
  }, [filterAction]);

  const clearFiltersActionsDesktop = useCallback(() => {
    clearFiltersAction();
    filterFormRef.current?.focus();
  }, [clearFiltersAction]);

  const clearFiltersKeyDownDesktop = useCallback(
    event => {
      const { keyCode } = event;

      if (keyCode === KEY_ENTER) {
        clearFiltersActionsDesktop();
      }
    },
    [clearFiltersActionsDesktop],
  );

  const removeFilterDesktop = useCallback(
    (group, filterId) => {
      filterAction(applyFilter, group, filterId, true);
      filterFormRef.current?.focus();
    },
    [filterAction],
  );

  const handleKeyPressDesktop = useCallback(
    (event, group, filterId) => {
      const { keyCode } = event;

      if (keyCode === KEY_ENTER) {
        removeFilterDesktop(group, filterId);
      }
    },
    [removeFilterDesktop],
  );

  if ((!groups || !groups.length) && !forceOfferToggle) return null;

  return (
    <section className={styles.filterForm} tabIndex={-1} ref={filterFormRef}>
      <SSRMediaQuery maxWidth={BREAKPOINTS.lg} groupId="search-filter-form-max-lg">
        <FiltersMobile
          appliedFilters={appliedFilters}
          groups={groups as Groups}
          disabled={isLoading}
          onChange={changeFilter}
          onClear={clearFiltersAction}
          stickyFilter={stickyFilter}
          forceOfferToggle={forceOfferToggle}
        />
      </SSRMediaQuery>
      <SSRMediaQuery
        className={styles.ssrDesktopWrapper}
        minWidth={BREAKPOINTS.lg}
        groupId="search-filter-form-min-lg"
      >
        {!forceOfferToggle && (
          <FilterCombos disabled={isLoading} groups={groups} onChange={changeFilter} />
        )}
        <OfferToggle disabled={isLoading} onChange={changeFilter} forceRender={forceOfferToggle} />
        {appliedFilters.length > 0 && (
          <>
            <div className={styles.break} />
            <footer data-testid="tabs-footer" className={styles.footer}>
              {appliedFilters.map(({ group, id, text }) => (
                <TextLink
                  className={styles.clearBtn}
                  component="button"
                  data-testid={`remove-filter-${id}`}
                  disabled={isLoading}
                  key={id}
                  id={id}
                  onClick={() => removeFilterDesktop(group, id)}
                  onKeyDown={event => handleKeyPressDesktop(event, group, id)}
                  data-webviewid="back-link"
                  underline="never"
                >
                  <span className="sr-only">Clear</span> {text}
                  <span className="sr-only">filter</span>
                  <RemoveSolid aria-hidden className={styles.icon} size="small" />
                </TextLink>
              ))}
              {appliedFilters.length > 1 && (
                <TextLink
                  className={styles.clearBtn}
                  component="button"
                  data-testid="btn-clear"
                  disabled={isLoading}
                  onClick={clearFiltersActionsDesktop}
                  onKeyDown={clearFiltersKeyDownDesktop}
                  data-webviewid="back-link"
                  underline="always"
                >
                  Clear all <span className="sr-only">filters</span>
                </TextLink>
              )}
            </footer>
          </>
        )}
      </SSRMediaQuery>
    </section>
  );
};

export default FilterForm;
