import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { loadable } from 'components/Skeleton/LoadableSkeleton';
import { rootPath } from 'utils/root-path';
import Breadcrumbs from 'components/Breadcrumbs';
import cmsPageStyles from '../../CmsPage/CmsPage.scss';
import PrebuiltPlanner from './PrebuiltPlanner';

/* istanbul ignore next */
const HowItWorks = loadable(() => import('./HowItWorks'));
/* istanbul ignore next */
const Step1 = loadable(() => import('./Step-1'));
/* istanbul ignore next */
const Step2 = loadable(() => import('./Step-2'));
/* istanbul ignore next */

const path = rootPath('/recipes/meal-plans');

export const MealPlanner = () => {
  return (
    <>
      <div className={cmsPageStyles.header}>
        <Breadcrumbs
          breadcrumbs={[
            { name: 'Recipes', path: rootPath('/recipes') },
            { name: 'Meal plans', path },
          ]}
        />
      </div>

      <Routes>
        <Route path="/how-to" element={<HowItWorks />} />
        <Route path="/serves" element={<Step1 />} />
        <Route path="/dietary" element={<Step2 />} />
        <Route path="*" element={<PrebuiltPlanner />} />
      </Routes>
    </>
  );
};
