import React, { useCallback, useEffect, useRef, useState } from 'react';
import { node, oneOf, string } from 'prop-types';
import classNames from 'classnames';
import root from 'window-or-global';
import { ChevronDown } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { useLocation } from 'react-router-dom';

import { useOnResize } from 'hooks/use-on-resize';

import Button from 'components/Button';
import EventMonitor from 'components/EventMonitor/EventMonitor';
import trolleyQueue from 'redux/middleware/trolley-queue';
import VisibilityProvider from './VisibilityProvider';

import styles from './SeeMore.scss';

/**
 * This component has been carefully designed so that it renders at a
 * STABLE HEIGHT when HTML from SSR is displayed by the browser and
 * does NOT rely on JS for initial sizing or perform visible changes
 * to its layout and styling when the component mounts.
 *
 * This is crucially important to CLS Web Metrics for SEO.
 *
 * For this reason, instead of accepting number props for collapsed
 * height in each viewport it accepts a class name that should have SASS
 * media query rules setting the `max-height` at each breakpoint.
 *
 * This way, the initial layout will be correct even before JS is loaded,
 * and when the component is mounted (or window resized) it measures the
 * DOM to obtain the collapsed `max-height` and full content height values.
 *
 * The expander button/bar is positioned absolutely in a reserved space
 * and shown by JS if required so as not to affect height and cause CLS
 */
const SeeMore = ({ children, maxHeightClass, theme }) => {
  const { pathname } = useLocation();
  const contentRef = useRef();
  const maxHeightRef = useRef();
  const [animated, setAnimated] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [heightCollapsed, setHeightCollapsed] = useState(null);
  const [heightExpanded, setHeightExpanded] = useState(null);
  const [loading, setLoading] = useState(true);

  const toggleExpanded = useCallback(() => {
    setAnimated(true);
    setExpanded(value => !value);
  }, []);

  const updateExpanderVisibility = useCallback(() => {
    if (contentRef.current === null) return;
    const { scrollHeight: contentHeight } = contentRef.current;
    const { maxHeight } = root.getComputedStyle(maxHeightRef.current);
    const collapsedMaxHeight = parseInt(maxHeight, 10);

    setHeightCollapsed(collapsedMaxHeight);
    setHeightExpanded(contentHeight);
    setHasMore(contentHeight > collapsedMaxHeight);
  }, []);

  useEffect(() => {
    setAnimated(false);
    setExpanded(false);
    updateExpanderVisibility();
  }, [pathname, children, updateExpanderVisibility]);

  useEffect(() => {
    setLoading(false);
  }, []);

  useOnResize(updateExpanderVisibility);

  const maxHeightStyle = { maxHeight: expanded ? heightExpanded : heightCollapsed };
  const contentStyle = hasMore ? maxHeightStyle : null;
  const ctaVisibleStyle = hasMore ? { visibility: 'visible' } : null;

  return (
    <div
      data-testid="see-more-div"
      className={classNames(styles.root, {
        [styles.animated]: animated,
        [styles.expanded]: expanded,
        [styles.loading]: loading,
        [styles.themeGrey]: theme === 'grey',
        [styles.themeRed]: theme === 'red',
        [styles.themeWhite]: theme === 'white',
        [styles.themeExperiment]: theme === 'linksExperiment',
      })}
    >
      <div
        className={classNames(maxHeightClass, styles.content)}
        ref={contentRef}
        style={contentStyle}
      >
        {/* This invisible element always has max-height set so that it can be
         measured regardless of whether the content element is expanded or not */}
        <div className={classNames(maxHeightClass, styles.measurable)} ref={maxHeightRef} />
        <div className={styles.fader} style={ctaVisibleStyle} />
        <VisibilityProvider expanded={expanded || !hasMore} collapsedHeight={heightCollapsed}>
          {children}
        </VisibilityProvider>
      </div>
      <div className={styles.cta} data-testid="see-more-expander" style={ctaVisibleStyle}>
        <div className={styles.button}>
          <EventMonitor
            actionType="redirect"
            customData={{
              'data-category': 'Browse',
            }}
            originComponent="see more"
            shortDescription="see more button"
          >
            <Button
              aria-label="see more category links"
              data-testid="expand-button"
              displayAsLink
              className={styles.expandButton}
              onClick={toggleExpanded}
            >
              <span className={styles.label}>{expanded ? 'Show fewer' : 'Show all'}</span>
              <span className={styles.icon}>
                <ChevronDown size="small" />
              </span>
            </Button>
          </EventMonitor>
        </div>
      </div>
    </div>
  );
};

SeeMore.propTypes = {
  theme: oneOf(['grey', 'red', 'white']),
  children: node,
  maxHeightClass: string.isRequired,
};

SeeMore.defaultProps = {
  theme: 'grey',
  children: null,
};

export default SeeMore;
