import React from 'react';

import urls from 'constants/urls';

import AnchorLink from 'components/AnchorLink';

import styles from './CustomerServiceLinks.scss';

const CustomerServiceLinks = () => (
  <div className={styles.wrapper} data-testid="customer-service-links">
    <AnchorLink
      className={styles.link}
      key="customerService-find-store-link"
      to="https://www.waitrose.com/find-a-store/"
      data-testid="find-store"
    >
      Find a store
    </AnchorLink>
    <AnchorLink
      className={styles.link}
      key="customerService-customer-service-link"
      to={urls.customerServiceHomePage}
      data-testid="customer-service"
    >
      Customer service
    </AnchorLink>
  </div>
);

CustomerServiceLinks.displayName = 'CustomerServiceLinks';

export default CustomerServiceLinks;
