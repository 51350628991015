// Controls pages that will have self-referencing canonical tags - i.e. be regarded as canonical versions
// Can accept Route style wildcards such as '/ecom/*' to match child pages since it uses pathToRegexp

export const canonical = [
  '/ecom/shop/browse/groceries/*rest',
  '/ecom/shop/browse/entertaining/*rest',
  '/ecom/shop/offers/*rest',
  '/ecom/products/*rest',
  '/ecom/shop/featured/meal-deals/*rest',
];
