import React, { useCallback, useMemo } from 'react';
import uuid from 'uuid/v4';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { useWtrDispatch, useWtrSelector } from 'redux/hooks';

import { toggleProductModal } from 'redux/modules/trolley/actions/toggle-product-modal';
import { getSwapUnavailableProductModal } from 'redux/modules/trolley/selectors/get-swap-unavailable-product-modal';
import { getSubProductsByLineNumber } from 'redux/modules/trolley/selectors/get-item-sub-products';
import { notifyModalClosed } from 'redux/modules/common-modal/actions';
import { getMoreFromThisBrandPayloadByLineNumber } from 'redux/modules/experiments/selectors';

import { moreFromThisBrandModal } from 'constants/modals';
import { FAVOURITES_MORE_FROM_THIS_BRAND } from 'constants/monetateExperiments';

import { useExperiments } from 'components/Experiment/useExperiments';
import SubsProductList from 'components/SwapUnavailableProductModal/SubsProductList';
import MoreFromThisBrandTitle from 'components/MoreFromThisBrandModal/MoreFromThisBrandTitle';

import styles from './index.scss';

const emptySection = (
  <>
    <Typography
      className={styles.emptyTitle}
      element="h3"
      fixedSize
      noMargins
      styleAs="sectionHeading"
    >
      Unable to load products
    </Typography>
    <Typography className={styles.emptyMessage} fixedSize styleAs="sectionHeadingLight">
      Please refresh the page, check your internet connection or try again later
    </Typography>
  </>
);

const MoreFromThisBrandModal = () => {
  const dispatch = useWtrDispatch();

  const { getDecisionById } = useExperiments();
  const isMoreFromThisBrandModalVariant =
    getDecisionById(FAVOURITES_MORE_FROM_THIS_BRAND.experiment).variant ===
    FAVOURITES_MORE_FROM_THIS_BRAND.variant.showMoreFromThisBrand;

  const { isOpen, lineNumber, modalId } = useWtrSelector(getSwapUnavailableProductModal);

  const toggleMoreFromThisBrandModal =
    isMoreFromThisBrandModalVariant && modalId === moreFromThisBrandModal.id;

  const onCloseModal = useCallback(() => {
    dispatch(toggleProductModal());
    dispatch(
      notifyModalClosed({
        ...moreFromThisBrandModal,
        title: moreFromThisBrandModal.titleText,
      }),
    );
  }, [dispatch]);

  const moreFromThisBrandDetails = useWtrSelector(state =>
    getMoreFromThisBrandPayloadByLineNumber(state, lineNumber),
  );

  const id = uuid();
  const titleLine1 = moreFromThisBrandDetails?.titleLine1;
  const titleLine2 = moreFromThisBrandDetails?.titleLine2;

  const titleText = useMemo(
    () => <MoreFromThisBrandTitle id={id} titleLine1={titleLine1} titleLine2={titleLine2} />,
    [id, titleLine1, titleLine2],
  );

  // @ts-expect-error getSubProductsByLineNumber selector is not typed
  const products = useWtrSelector(state => getSubProductsByLineNumber(state, lineNumber));

  if (!toggleMoreFromThisBrandModal) {
    return null;
  }

  return (
    <Modal
      ariaLabelledBy={id}
      className={styles.modal}
      contentClassName={styles.modalContent}
      handleModalClose={onCloseModal}
      isOpen={isOpen}
      // @ts-expect-error titleText can render any React Node but has been typed to just strings
      titleText={titleText}
      standardHeadingEnabled
    >
      <SubsProductList
        emptySection={emptySection}
        largeThumbnails
        lineNumber={lineNumber}
        productPodAddText="Add"
        maxProducts={16}
      />
      <section className={styles.buttonWrapper}>
        <Button
          data-testid="close-modal-btn"
          onClick={onCloseModal}
          label={products.length === 0 ? 'Close' : 'Done'}
          theme="finalising"
        />
      </section>
    </Modal>
  );
};

export default MoreFromThisBrandModal;
