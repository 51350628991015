import React from 'react';
import PropTypes from 'prop-types';

import urls from 'constants/urls';
import { User } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { LoginLink } from 'components/AuthenticationAction/LoginLink';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import styles from './SignIn.scss';

const SignIn = ({ className, loggedIn }) =>
  loggedIn ? null : (
    <>
      <LoginLink className={className} data-test="loginAnchor" underline="underline">
        <User className={styles.signInIcon} />
        Sign in
      </LoginLink>
      <TextLink
        className={className}
        data-test="registerAnchor"
        href={urls.tokenClientRegistration}
        underline="underline"
      >
        Register
      </TextLink>
    </>
  );

SignIn.propTypes = {
  className: PropTypes.string,
  loggedIn: PropTypes.bool.isRequired,
};

SignIn.defaultProps = {
  className: '',
};

SignIn.displayName = 'SignIn';

export default SignIn;
